import React, { useState } from 'react';
import { Container, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import axios from 'utils/axiosService';
import Swal from 'sweetalert2';
import MainLayout from 'components/MainLayout';
import CSRFToken from 'components/Authentication/CsrfToken';
import { getCookie } from 'utils/csrf';
import {useNavigate} from 'react-router-dom';

function PasswordReset() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const csrfToken = getCookie('csrftoken');
      const requestData = {
        email: email,
      };

      const config = {
        headers: {
          'X-CSRFToken': csrfToken,
        },
        withCredentials: true,
      };

      await axios.post('password-reset/', requestData, config);

      Swal.fire({
        icon: 'success',
        title: 'Password Reset Email Sent',
        text: 'Your password reset email has been sent. Please check your inbox for further instructions on how to reset your password.',
      });
      navigate('/logout');
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred. Please try again later.',
      });
    }
  };

  return (
    <MainLayout title="Reset Password" header="Reset Password">
      <Container>
        <p>Please enter your email address below. We will send you an email with instructions on how to reset your password.</p>
        <Form onSubmit={handleSubmit}>
          <CSRFToken />
          <FormGroup>
            <Label for="email">Enter your email below:</Label>
            <Input
              type="email"
              id="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>
          <Button type="submit" color="primary">Reset Password</Button>
        </Form>
      </Container>
    </MainLayout >
  );
}

export default PasswordReset;
