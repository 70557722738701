import React from 'react';
import PropTypes from 'prop-types';

const Header = ({ content }) => {
  return (
    <>
      <h1 className={'main-header pt-3 my-4 ml-4'}>
        {content}
      </h1>
    </>
  );
};


Header.propTypes = {
  content: PropTypes.node,
};

export default Header;
