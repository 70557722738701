import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Button, Input } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'utils/axiosService';

const FileUpload = ({ onSave }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSave = async () => {
    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await axios.post('workflows/file-upload/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        const { file_id, file_name, file_path, file_type } = response.data;
        console.log(response);
        const integrationData = {
          integration_id: 2,
          file_id: file_id,
          file_name: file_name,
          file_path: file_path,
          file_type: file_type,
          format: 'csv',
        };
        onSave(integrationData);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <Container>
      <Row>
        <Input className={'mb-3'} type="file" onChange={handleFileChange} />
        <Button onClick={handleSave}>Upload</Button>
      </Row>
    </Container>
  );
};

FileUpload.propTypes = {
  onSave: PropTypes.func.isRequired,
};

export default FileUpload;