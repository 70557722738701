import React from 'react';
import PropTypes from 'prop-types';

const SelectAllCheckbox = ({ checked, onChange }) => {
  return (
    <div style={{ marginLeft: 0.5 + 'em' }} className="form-check mb-2">
      <input
        type="checkbox"
        className="form-check-input"
        id="selectAll"
        checked={checked}
        onChange={onChange}
      />
      <label className="form-check-label" htmlFor="selectAll">
        Select All
      </label>
    </div>
  );
};

SelectAllCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SelectAllCheckbox;