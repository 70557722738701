import React, {useEffect, useState} from 'react';
import {Card, CardTitle, Col, Container, Row} from 'reactstrap';
import PropTypes from 'prop-types';
import axiosInstance from '../../../utils/axiosService';

const ListIntegrations = ({ onSelect }) => {
  const [integrations, setIntegrations] = useState([]);

  useEffect(() => {
    const fetchIntegrations = async () => {
      try {
        const response = await axiosInstance.get('integrations/');
        setIntegrations(response.data);
      } catch (error) {
        console.error('Error fetching integrations:', error);
      }
    };

    fetchIntegrations();
  }, []);
    

  return (
    <Container>
      <Row>
        {integrations.map(integration => (
          <Col key={integration.name} md="2" className="mb-3">
            <Card className={'cursor-pointer pt-4'} onClick={() => {
              if(onSelect) {
                onSelect(integration.name);
              }
            }}>
              <img
                className={'mx-auto'}
                src={process.env.REACT_APP_DJANGO_HOST + '/static/' + integration.icon}
                alt={integration.name}
                style={{ width: '60px', marginRight: '10px' }}
              />
              <CardTitle className={'mx-auto mt-2 truncate-text'}>{integration.name}</CardTitle>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

ListIntegrations.propTypes = {
  onSelect: PropTypes.func,
};

export default ListIntegrations;
