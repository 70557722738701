import React, { useState, useEffect, useCallback } from 'react';
import axios from 'utils/axiosService';
import MainLayout from 'components/MainLayout';
import BotTable from 'components/Chatbots/BotTable';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';


const ListBots = () => {
  const [bots, setBots] = useState([]);
  const navigate = useNavigate();

  const fetchBots = useCallback(async () => {
    try {
      const response = await axios.get('bots/');
      setBots(response.data);
    } catch (error) {
      if (error.isUnauthorizedError) {
        navigate('/login');
      }
    }
  }, [navigate]);

  useEffect(() => {
    fetchBots();
  }, [fetchBots]);

  const createNewBot = () => {
    navigate('/bots/create');
  };

  const onDeleteSelected = () => {
    fetchBots();
  };

  return (
    <MainLayout title="Chat Bots" header="Chat Bots">
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
        <Button color="primary" onClick={createNewBot}>Create New Bot</Button>
      </div>
      <BotTable bots={bots} onDeleteSelected={onDeleteSelected} />
    </MainLayout>
  );
};

export default ListBots;
