import React, { useState, useEffect, useRef } from 'react';
import axios from 'utils/axiosService';
import { useParams } from 'react-router-dom';
import { Container, Form, Input, Button, Row, Col } from 'reactstrap';
import MainLayout from 'components/MainLayout';

import 'styles/playground.css';
import Swal from 'sweetalert2';

const Playground = () => {
  const { id } = useParams();
  const [model, setModel] = useState('Claude 3 Sonnet');
  const [bot, setBot] = useState({});
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const playgroundWindowRef = useRef(null);

  const models = [
    'Azure GPT 3.5',
    'Azure GPT 4',
    'OpenAI GPT 4',
    'OpenAI GPT 4 Turbo',
    'OpenAI GPT 4o',
    'Claude 3 Sonnet',
    'Claude 3 Haiku',
    'Cohere Command R',
    'Cohere Command R+',
    // 'AI21 Labs Jurassic 2 Ultra',
    // 'Llama 3 8B Instruct',
    // 'Mistral 7B Instruct',
  ];

  useEffect(() => {
    const fetchBotData = async () => {
      try {
        const response = await axios.get(`/bots/${id}/`);
        setBot(response.data);
      } catch (error) {
        console.error('An error occurred while fetching bot data:', error);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'An error occurred:' + error,
        });
      }
    };

    fetchBotData();
  }, [id]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    playgroundWindowRef.current.scrollTop = playgroundWindowRef.current.scrollHeight;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const newMessages = [...messages, { role: 'user', content: input }];
    setMessages(newMessages);  // Add the user message to the messages array
    setInput('');  // Clear the input

    // Add a temporary ellipsis message
    setMessages(prevMessages => [...prevMessages, { role: 'system', content: '...' }]);

    try {
      const response = await axios.post(`/chat/${id}/`, { 'messages': newMessages, 'model': model });
      // Remove the temporary ellipsis message and replace with the actual response
      setMessages(prevMessages => {
        const updatedMessages = [...prevMessages];
        updatedMessages[updatedMessages.length - 1] = { role: 'system', content: response.data };
        return updatedMessages;
      });
    } catch (error) {
      setMessages(prevMessages => {
        const updatedMessages = [...prevMessages];
        updatedMessages[updatedMessages.length - 1] = { role: 'system', content: 'I\'m sorry, it seems like something went wrong: ' + error};
        return updatedMessages;
      });
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  return (
    <MainLayout title="Bot Details" header={bot.name + ' - Playground'}>
      <select value={model} onChange={(e) => setModel(e.target.value)}>
        {models.map((model, index) => (
          <option key={index} value={model}>
            {model}
          </option>
        ))}
      </select>
      <Container>
        <div className="playground-window" ref={playgroundWindowRef}>
          {messages.map((message, index) => (
            <div className={`playground-message-container ${message.role}`} key={index}>
              <div className={`mb-4 playground-message ${message.role}`}
                dangerouslySetInnerHTML={{__html: message.content}}>
              </div>
            </div>
          ))}
        </div>
        <Form onSubmit={handleSubmit} className="playground-input-form">
          <Row>
            <Col md={10}>
              <Input type="text" placeholder="Type your message..." value={input}
                onChange={(e) => setInput(e.target.value)}/>
            </Col>
            <Col md={2}>
              <Button type="submit">Send</Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </MainLayout>
  );
};

export default Playground;
