import React, { useState, useEffect, useCallback } from 'react';
import MainLayout from 'components/MainLayout';
import CardComponent from 'components/Homepage/CardComponent';
import SmallUsageGraph from 'components/Homepage/SmallUsageGraph';
import moment from 'moment';
import axios from 'utils/axiosService';
import {toast} from 'react-toastify';

const Homepage = () => {
  const [userDetails, setUserDetails] = useState({user: {welcomeMessage: 'Welcome back!'}});
  const [bots, setBots] = useState([]);
  const [workflows, setWorkflows] = useState([]);
  const [metricsData, setMetricsData] = useState(null);

  const fetchUserDetails = useCallback(async () => {
    try {
      const response = await axios.get('account/');
      const user = response.data;

      user.welcomeMessage = user.user.first_name ? `Welcome back, ${user.user.first_name}!` : 'Welcome back!';

      setUserDetails(user);
    } catch (error) {
      toast.error('Error fetching user details.');
    }
  }, []);

  const fetchBots = useCallback(async () => {
    try {
      const response = await axios.get('bots/', {params: {limit: 4}});
      setBots(response.data);
    } catch (error) {
      toast.error('Error fetching bots.');
    }
  }, []);

  const fetchWorkflows = useCallback(async () => {
    try {
      const response = await axios.get('workflows/', {params: {limit: 4}});
      setWorkflows(response.data);
    } catch (error) {
      toast.error('Error fetching workflows.');
    }
  }, []);

  useEffect(() => {
    fetchUserDetails();
    fetchBots();
    fetchWorkflows();
  }, [fetchBots, fetchWorkflows]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('metrics/?period=week');
        setMetricsData(response.data);
      } catch (error) {
        toast.error('Error fetching metrics.');
      }
    };
    fetchData();
  }, []);

  return (
    <MainLayout title="Homepage" header={userDetails.welcomeMessage}>
      <div className={'d-flex flex-wrap justify-content-between gap-3 py-4 px-3'}>
        <CardComponent
          title="Workflows"
          className="workflows-card"
          tableHeaders={['Name', 'Last Run', 'Token Count']}
          tableData={workflows.map(workflow => [workflow.name, moment(workflow.created_on).format('DD/MM/YYYY - HH:mm:ss'), '488'])}
        />
        <CardComponent
          title="Chatbots"
          className="chatbots-card"
          tableHeaders={['Name', 'Last Run', 'Token Count']}
          tableData={bots.map(bot => [bot.name, moment(bot.created_on).format('DD/MM/YYYY - HH:mm:ss'), '488'])}
        />
        <CardComponent
          title="Usage"
          className="usage-card"
          customContent={<SmallUsageGraph data={metricsData} />}
        />
        <CardComponent
          title="Account Details"
          className="account-details-card"
          tableHeaders={['Name', 'Last Run', 'Token Count']}
          tableData={bots.map(bot => [bot.name, moment(bot.created_on).format('DD/MM/YYYY - HH:mm:ss'), '488'])}
        />
      </div>
    </MainLayout>
  );
};

export default Homepage;