import React from 'react';
import PropTypes from 'prop-types';

const CardComponent = ({ title, tableHeaders, tableData, customContent, className }) => (
  <div className={`homepage-card mb-3 p-3 rounded-3 border border-2 ${className || ''}`}>
    <h1 className={'mb-3'}>
      {title}
    </h1>
    {customContent ? (
      <div className={'overflow-hidden'}>
        {customContent}
      </div>
    ) : (
      <table className={'homepage-card-table w-100 table-fixed'}>
        <thead>
          <tr className={'homepage-card-table-header overflow-hidden'}>
            {tableHeaders.map((header, index) => (
              <th key={index}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((rowData, rowIndex) => (
            <tr key={rowIndex}>
              {rowData.map((cellData, cellIndex) => (
                <td key={cellIndex} className={'overflow-hidden'}>
                  {cellData}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    )}
  </div>
);

CardComponent.propTypes = {
  title: PropTypes.string.isRequired,
  tableHeaders: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  customContent: PropTypes.node,
  className: PropTypes.string
};

export default CardComponent;
