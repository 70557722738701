import React, { useState, useEffect } from 'react';
import {Container, Button, Label, Input, FormGroup, Card} from 'reactstrap';
import PropTypes from 'prop-types';
import IntegrationsModal from './DataSources/IntegrationsModal';
import axiosService from 'utils/axiosService';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const WorkflowForm = ({ data }) => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState('input');
  const [isWorkflowRunning, setIsWorkflowRunning] = useState(false);
  const [inputIntegration, setInputIntegration] = useState(null);
  const [outputIntegration, setOutputIntegration] = useState(null);

  // Initialize the WorkflowData state
  const [workflowData, setWorkflowData] = useState({
    name: '',
    inputs: [],
    triggers: [],
    prompt: '',
    outputs: []
  });

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const updateModalType = (type) => () => {
    setModalType(type);
    toggleModal();
  };

  useEffect(() => {
    // Check if there's an input integration to fetch
    if (workflowData.inputs && workflowData.inputs.length > 0) {
      const integrationId = workflowData.inputs[0].integration_id;
      axiosService.get(`/integrations/${integrationId}/`)
        .then(response => {
          setInputIntegration(response.data);
        })
        .catch(() => {
          toast.error('An error occurred while fetching input integration data.');
        });
    }

    // Similarly, fetch the output integration if available (assuming a similar structure)
    if (workflowData.outputs && workflowData.outputs.length > 0) {
      const integrationId = workflowData.outputs[0].integration_id;
      axiosService.get(`/integrations/${integrationId}/`)
        .then(response => {
          setOutputIntegration(response.data);
        })
        .catch(() => {
          toast.error('An error occurred while fetching output integration data.');
        });
    }

  }, [workflowData]);


  useEffect(() => {
    if (data) {
      setWorkflowData(data);
    }
  }, [data]);

  const handlePromptChange = (e) => {
    const updatedPrompt = e.target.value;
    setWorkflowData(prevData => ({
      ...prevData,
      prompt: updatedPrompt
    }));
  };

  const handleNameChange = (e) => {
    const updatedName = e.target.value;
    setWorkflowData(prevData => ({
      ...prevData,
      name: updatedName
    }));
  };

  const saveWorkflow = (e) => {
    e.preventDefault();
    axiosService.post('/workflows/', workflowData)
      .then(response => {
        toast.success('Workflow saved successfully!');
        const workflowId = response.data.id;
        navigate('/workflows/' + workflowId + '/');
      })
      .catch(() => {
        toast.error('An error occurred while saving.');
      });
  };

  const filterEmptyFields = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([, value]) => value != null && value !== '' && value !== [])
    );
  };

  const updateWorkflow = (e) => {
    e.preventDefault();
    if (!data?.id) {
      console.error('No workflow ID provided.');
      return;
    }

    const filteredData = filterEmptyFields(workflowData);

    axiosService.patch('/workflows/' + data.id + '/', filteredData)
      .then(response => {
        toast.success('Workflow updated successfully!');
        const workflowId = response.data.id;
        navigate('/workflows/' + workflowId + '/');
      })
      .catch(() => {
        toast.error('An error occurred while updating.');
      });
  };

  const runWorkflow = () => {
    const workflowId = data?.id;
    setIsWorkflowRunning(true);

    // Make a POST request to run the workflow
    axiosService.post('/workflows/run/', { workflow_id: workflowId })
      .then(response => {
        setIsWorkflowRunning(false);
        Swal.fire({
          icon: 'success',
          title: 'Workflow Ran Successfully',
          text: JSON.stringify(response.data, null, 2),
        });
      })
      .catch(() => {
        setIsWorkflowRunning(false);
        toast.error('An error occurred while running the workflow.');
      });
  };


  return (
    <Container fluid>
      <FormGroup className="mb-3">
        <Label for="name"><strong>Name:</strong></Label>
        <Input
          type="text"
          id="name"
          name="name"
          required
          value={workflowData.name}
          onChange={handleNameChange}
        />
      </FormGroup>
      <FormGroup className="mb-3">
        <Card onClick={updateModalType('input')} className={'mx-auto p-3'}>
          {inputIntegration ?
            <>
              <img className={'mx-auto integration-data-source-icon'} src={process.env.REACT_APP_DJANGO_HOST + '/static/' + inputIntegration.icon} alt={inputIntegration.name} />
              <Label className={'mx-auto'}><strong>Input Source: {workflowData.inputs[0].file_name}</strong></Label>
            </>
            :
            <>
              <img className={'mx-auto integration-data-source-icon'} src={process.env.PUBLIC_URL + '/icons/integration-input.svg'} alt="Integration Input" />
              <Label className={'mx-auto'}><strong>Input Source</strong></Label>
            </>
          }
        </Card>
      </FormGroup>
      <FormGroup className="mb-3">
        <Label for="prompt"><strong>Prompt:</strong></Label>
        <Input
          type="textarea"
          id="prompt"
          name="prompt"
          required
          value={workflowData.prompt}
          onChange={handlePromptChange}
          rows='10'
        />
      </FormGroup>
      <FormGroup className="mb-3">
        <Card onClick={updateModalType('output')} className={'mx-auto p-3'}>
          {outputIntegration ?
            <>
              <img className={'mx-auto integration-data-source-icon'} src={process.env.REACT_APP_DJANGO_HOST + '/static/' + outputIntegration.icon} alt={outputIntegration.name} />
              <Label className={'mx-auto'}><strong>Output Source: {workflowData.outputs[0].file_name}</strong></Label>
            </>
            :
            <>
              <img className={'mx-auto integration-data-source-icon'} src={process.env.PUBLIC_URL + '/icons/integration-output.svg'} alt="Integration Output" />
              <Label className={'mx-auto'}><strong>Output Source</strong></Label>
            </>
          }
        </Card>

      </FormGroup>

      <div className="text-right d-flex justify-content-end">
        <Button color="primary" onClick={runWorkflow} disabled={isWorkflowRunning} className={'mx-2'}>
          {
            isWorkflowRunning ? (
              <>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {' '}Running...
              </>
            ) : 'Run'
          }
        </Button>

        <Button color="success" onClick={data?.id ? updateWorkflow : saveWorkflow}>
          Save
        </Button>
      </div>

      <IntegrationsModal isOpen={modalOpen} toggleModal={toggleModal} setWorkflowData={setWorkflowData} type={modalType} />
    </Container>
  );
};

WorkflowForm.propTypes = {
  data: PropTypes.object,
};

export default WorkflowForm;