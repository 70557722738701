import React, { useState, useEffect } from 'react';
import { Row, Button } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'utils/axiosService';
import PropTypes from 'prop-types';

const FileUploadComponent = ({ onFileUpload, buttonText = 'Upload', buttonColor = 'secondary', allowMultiple = true, files = [], onDeleteFile, id, action }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  useEffect(() => {
    setSelectedFiles(files);
    console.log(`Initial Files for ${action} card number ${id}:`, files);
  }, [files, action, id]);

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    const updatedFiles = allowMultiple ? [...selectedFiles, ...newFiles] : newFiles;
    newFiles.forEach(file => handleSave(file, updatedFiles));
  };

  const handleSave = async (file, updatedFiles) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('workflows/file-upload/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        const fileData = response.data;
        const integrationData = {
          integration_id: 2,
          ...fileData,
          format: 'csv',
        };
        onFileUpload(integrationData);
        setSelectedFiles(updatedFiles); // Update state with the new file info
        console.log('File uploaded:', integrationData);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleDelete = (index) => {
    if (onDeleteFile) {
      onDeleteFile(index);
    }
    setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index)); // Update state locally to reflect the deletion
  };

  return (
    <Row className="file-upload-row m-0">
      {selectedFiles.map((file, index) => (
        <div className="file-info m-0 p-0" key={index}>
          <span className="truncate-card-text">{file.name || file.file_name}</span>
          <Button
            color="link"
            size="sm"
            onClick={() => handleDelete(index)}
          >X
          </Button>
        </div>
      ))}
      <input
        type="file"
        id={`fileInput-${id}`}
        onChange={handleFileChange}
        style={{ display: 'none' }}
        multiple={allowMultiple}
      />
      <Button onClick={() => document.getElementById(`fileInput-${id}`).click()} color={buttonColor} className="auto-width mt-1">
        {buttonText}
      </Button>
    </Row>
  );
};

FileUploadComponent.propTypes = {
  onFileUpload: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  buttonColor: PropTypes.string,
  allowMultiple: PropTypes.bool,
  files: PropTypes.array.isRequired,
  onDeleteFile: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  action: PropTypes.string.isRequired,
};

export default FileUploadComponent;
