import { createContext, useState, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import axiosInstance from 'utils/axiosService';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(() => {
    const access = localStorage.getItem('accessToken');
    const refresh = localStorage.getItem('refreshToken');
    return access && refresh ? { access, refresh } : null;
  });

  const setToken = (data) => {
    if (data) {
      localStorage.setItem('accessToken', data.access);
      localStorage.setItem('refreshToken', data.refresh);
    } else {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
    }
    setAuthToken(data);
  };

  const refreshToken = useCallback(async () => {
    try {
      const response = await axiosInstance.post('token/refresh/', {
        refresh: localStorage.getItem('refreshToken')
      });
      const { access, refresh } = response.data;
      setToken({ access, refresh });
      return access;
    } catch (error) {
      console.error('Failed to refresh token', error);
      setToken(null);
      window.location.href = '/login';
      return null;
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      await refreshToken();
    }, 4 * 60 * 1000); // Refresh every 4 minutes

    return () => clearInterval(interval);
  }, [refreshToken]);

  return (
    <AuthContext.Provider value={{ authToken, setAuthToken: setToken, refreshToken }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export const useAuth = () => {
  return useContext(AuthContext);
};
