import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Table } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import axiosService from 'utils/axiosService';
import moment from 'moment';
import SelectAllCheckbox from 'components/Common/SelectAllCheckbox';
import TrashIcon from 'components/Common/TrashIcon';

const BotTable = ({ bots, onDeleteSelected }) => {
  const [selectedBots, setSelectedBots] = useState([]);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (deleteConfirmed) {
      setSelectedBots([]);
      setDeleteConfirmed(false);
    }
  }, [deleteConfirmed]);

  const handleRowClick = (botId) => {
    navigate(`/bots/${botId}`);
  };

  const handleCheckBoxClick = (e, botId) => {
    e.stopPropagation();
    if (selectedBots.includes(botId)) {
      setSelectedBots(selectedBots.filter(id => id !== botId));
    } else {
      setSelectedBots([...selectedBots, botId]);
    }
  };

  const handleTrashClick = (botId) => {
    handleDelete(botId);
  };

  const handleSelectAllChange = (e) => {
    if (e.target.checked) {
      const allBotIds = bots.map(bot => bot.id);
      setSelectedBots(allBotIds);
    } else {
      setSelectedBots([]);
    }

    const checkboxes = document.querySelectorAll('.checkbox-select');
    checkboxes.forEach(checkbox => {
      checkbox.checked = e.target.checked;
    });
  };

  const handleDelete = (botIdToDelete) => {
    let botsForDeletion = selectedBots;
    if (botIdToDelete != null) {
      botsForDeletion = [botIdToDelete];
    }

    Swal.fire({
      title: 'Confirmation',
      text: 'Are you sure you want to delete the selected bots?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Wrap deletion requests in Promise.all to ensure all requests finish before proceeding
        await Promise.all(
          botsForDeletion.map(botId => axiosService({
            method: 'DELETE',
            url: `/bots/${botId}`,
          }))
        );

        // Trigger the delete confirmation
        setDeleteConfirmed(true);

        // Trigger parent callback function to refetch bots
        onDeleteSelected();

        uncheckSelectBoxes();

        Swal.fire(
          'Deleted!',
          'The bot(s) have been deleted.',
          'success'
        );
      }
    });
  };


  return (
    <div className="table-responsive">
      <SelectAllCheckbox
        checked={selectedBots.length === bots.length && selectedBots !== 0}
        onChange={handleSelectAllChange}
      />
      <Table hover>
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Name</th>
            <th scope="col">Description</th>
            <th scope="col">Type</th>
            <th scope="col">Chats</th>
            <th scope="col">Created At</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {bots.map((bot) => (
            <tr
              key={bot.id}
              style={{ cursor: 'pointer' }}
              onClick={() => handleRowClick(bot.id)}
            >
              <td>
                <input
                  type="checkbox"
                  className="form-check-input checkbox-select"
                  onClick={(e) => handleCheckBoxClick(e, bot.id)}
                />
              </td>
              <td>{bot.name}</td>
              <td>{bot.description}</td>
              <td>{bot.type}</td>
              <td>{bot.chats}</td>
              <td>{moment(bot.created_on).format('HH:mm:ss DD/MM/YYYY')}</td>
              <td>
                <TrashIcon objectId={bot.id} onTrashClick={handleTrashClick} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Button
        type="button"
        color="danger"
        onClick={() => handleDelete()}
        disabled={selectedBots.length === 0}
      >
        Delete Selected
      </Button>
    </div>
  );
};

BotTable.propTypes = {
  bots: PropTypes.array.isRequired,
  onDeleteSelected: PropTypes.func.isRequired,
};

export default BotTable;

function uncheckSelectBoxes() {
  const checkboxes = document.querySelectorAll('.checkbox-select');
  checkboxes.forEach(checkbox => {
    checkbox.checked = false;
  });
}
