import React, {useState} from 'react';
import {Button, Modal, ModalBody, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';

import GoogleSheets from './Integrations/GoogleSheets';
import FileUpload from './Integrations/FileUpload';
import ListIntegrations from './ListIntegrations';
import GoogleDocs from './Integrations/GoogleDocs';

const IntegrationsModal = ({ isOpen, toggleModal, setWorkflowData, type }) => {

  // New state for the selected integration
  const [selectedIntegration, setSelectedIntegration] = useState('');

  // Function to set the selected integration
  const selectIntegration = (integrationName) => {
    setSelectedIntegration(integrationName);
  };

  const handleSaveInputData = (integrationData) => {
    setWorkflowData(prevData => ({
      ...prevData,
      inputs: [integrationData]
    }));
    setSelectedIntegration('');
    console.log(integrationData);
    toggleModal();
  };

  const handleSaveOutputData = (integrationData) => {
    setWorkflowData(prevData => ({
      ...prevData,
      outputs: [integrationData]
    }));
    setSelectedIntegration('');
    toggleModal();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} size={'xl'}>
      <ModalHeader toggle={toggleModal}>
        {selectedIntegration !== '' ?
          <Button
            onClick={() => { setSelectedIntegration('');}}
            className="m-1"
          >
            Back
          </Button>
          :
          <></>
        }
        Select Data Source Type
      </ModalHeader>
      <ModalBody>
        {
          selectedIntegration === '' ? <ListIntegrations onSelect={selectIntegration} /> :
            selectedIntegration === 'Google Sheets' ? <GoogleSheets onSave={type === 'input' ? handleSaveInputData : handleSaveOutputData} /> :
              selectedIntegration === 'Google Docs' ? <GoogleDocs onSave={type === 'input' ? handleSaveInputData : handleSaveOutputData} /> :
                selectedIntegration === 'File Upload' ? <FileUpload onSave={type === 'input' ? handleSaveInputData : handleSaveOutputData} /> :
                  null
        }
      </ModalBody>
    </Modal>
  );
};

IntegrationsModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  setWorkflowData: PropTypes.func,
  type: PropTypes.string,
};

export default IntegrationsModal;