import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'contexts/AuthContext';

const Logout = () => {
  const navigate = useNavigate();
  const { setAuthToken } = useContext(AuthContext);

  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  setAuthToken(null);
  navigate('/login');
};

export default Logout;
