import React, { useState, useEffect } from 'react';
import MainLayout from 'components/MainLayout';
import { Container, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input } from 'reactstrap';
import axios from '../../utils/axiosService';
import { Bar } from 'react-chartjs-2';
import { LinearScale, CategoryScale, PointElement, LineElement, Chart, BarElement, Filler, Tooltip } from 'chart.js';
import { format, subDays, subMonths, subYears } from 'date-fns';

Chart.register(LinearScale, CategoryScale, PointElement, LineElement, BarElement, Filler, Tooltip);

const Metrics = () => {
  const [period, setPeriod] = useState('day');
  const [customStartDate, setCustomStartDate] = useState('');
  const [customEndDate, setCustomEndDate] = useState('');
  const [data, setData] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedPeriodText, setSelectedPeriodText] = useState('Past 24 hours'); // Track selected period text

  const toggle = () => setDropdownOpen(prevState => !prevState);

  useEffect(() => {
    const fetchData = async () => {
      let url = `metrics/?period=${period}`;
      if (customStartDate && customEndDate) {
        url += `&start=${customStartDate}&end=${customEndDate}`;
      }
      try {
        const response = await axios.get(url);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching metrics:', error);
      }
    };
    fetchData();
  }, [period, customStartDate, customEndDate]);

  const getDateRange = () => {
    const today = new Date();
    if (period === 'day') {
      return { start: subDays(today, 1), end: today };
    } else if (period === 'week') {
      return { start: subDays(today, 7), end: today };
    } else if (period === 'month') {
      return { start: subMonths(today, 1), end: today };
    } else if (period === 'year') {
      return { start: subYears(today, 1), end: today };
    }
    return null;
  };

  const dateRange = getDateRange();

  const timestamps = data && data.metrics ? data.metrics.map((item) => item.period) : [];
  const totalTokensData = data && data.metrics ? data.metrics.map((item) => item.total_tokens) : [];

  const formatTimestamps = () => {
    if (timestamps.length > 0) {
      if (period === 'day') {
        return timestamps.map((timestamp) => format(new Date(timestamp), 'HH:mm'));
      } else if (period === 'week') {
        return timestamps.map((timestamp) => format(new Date(timestamp), 'EEE'));
      } else if (period === 'month') {
        return timestamps.map((timestamp) => format(new Date(timestamp), 'dd MMM'));
      } else if (period === 'year') {
        return timestamps.map((timestamp) => format(new Date(timestamp), 'MMM yyyy'));
      }
      return timestamps;
    }
    return [];
  };

  return (
    <MainLayout title="Metrics" header="Metrics">
      <Container fluid>
        <Row>
          <Col>
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle caret>
                Select Period: {selectedPeriodText} {/* Display selected period */}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => { setPeriod('day'); setSelectedPeriodText('Past 24 hours'); }}>Past 24 hours</DropdownItem>
                <DropdownItem onClick={() => { setPeriod('week'); setSelectedPeriodText('Past 7 Days'); }}>Past 7 Days</DropdownItem>
                <DropdownItem onClick={() => { setPeriod('month'); setSelectedPeriodText('Past 30 Days'); }}>Past 30 Days</DropdownItem>
                <DropdownItem onClick={() => { setPeriod('year'); setSelectedPeriodText('Past 12 Months'); }}>Past 12 Months</DropdownItem>
              </DropdownMenu>
            </Dropdown>
            {period !== 'custom' && (
              <p>
                Viewing data for the {period === 'day' ? 'past 24 hours' : `past ${period}`}{' '}
                (from {format(dateRange.start, 'yyyy-MM-dd HH:mm')} to {format(dateRange.end, 'yyyy-MM-dd HH:mm')}).
              </p>
            )}
            {period === 'custom' && (
              <>
                Start Date: <Input type="date" value={customStartDate} onChange={e => setCustomStartDate(e.target.value)} />
                End Date: <Input type="date" value={customEndDate} onChange={e => setCustomEndDate(e.target.value)} />
              </>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {timestamps.length > 0 && (
              <Bar
                data={{
                  labels: formatTimestamps(),
                  datasets: [
                    {
                      label: 'Token Usage',
                      data: totalTokensData,
                      backgroundColor: 'rgb(169, 99, 255)',
                      borderWidth: 0,
                    },
                  ],
                }}
                options={{
                  scales: {
                    x: {
                      type: 'category',
                      title: {
                        display: true,
                        text: 'Time',
                      },
                    },
                    y: {
                      beginAtZero: true,
                      title: {
                        display: true,
                        text: 'Token Usage',
                      },
                    },
                  },
                  plugins: {
                    tooltip: {
                      mode: 'index',
                      callbacks: {
                        label: context => `Tokens: ${context.parsed.y}`,
                      },
                    },
                  },
                }}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Total Tokens for the Period: {data && data.total_tokens}</h5>
          </Col>
        </Row>
      </Container>
    </MainLayout>
  );
};

export default Metrics;
