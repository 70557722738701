import React, {useState, useEffect} from 'react';
import axiosInstance from 'utils/axiosService';
import MainLayout from 'components/MainLayout';
import {Container, Row, Col, Card, CardImg, CardBody, CardTitle, Button} from 'reactstrap';

const Integrations = () => {
  const [integrations, setIntegrations] = useState([]);

  const handleAuthorise = (url) => {
    return () => {
      const token = localStorage.getItem('token');
      window.location.href = process.env.REACT_APP_DJANGO_HOST + '/api/v1' + url + `?token=${JSON.parse(token)}&process=connect&action=reauthenticate`;
    };
  };

  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }


  const handleDeAuthorise = (url, allauth_id) => {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = process.env.REACT_APP_DJANGO_HOST + '/api/v1' + url;

    const csrfInput = document.createElement('input');
    csrfInput.type = 'hidden';
    csrfInput.name = 'csrfmiddlewaretoken';
    csrfInput.value = getCookie('csrftoken');
    form.appendChild(csrfInput);

    const accountInput = document.createElement('input');
    accountInput.type = 'hidden';
    accountInput.name = 'account';
    accountInput.value = allauth_id;
    form.appendChild(accountInput);

    document.body.appendChild(form);

    form.submit();
  };

  useEffect(() => {
    const fetchIntegrations = async () => {
      try {
        const response = await axiosInstance.get('integrations/');
        setIntegrations(response.data);
      } catch (error) {
        console.error('Error fetching integrations:', error);
      }
    };

    fetchIntegrations();
  }, []);

  return (
    <MainLayout title="Integrations" header="Integrations">
      <Container fluid>
        <Row>
          {integrations.map(integration => (
            <Col md={3} key={integration.id}>
              <Card className="mb-4 pt-4">
                <CardImg top className="w-25 mx-auto d-block"
                  src={process.env.REACT_APP_DJANGO_HOST + '/static/' + integration.icon}
                  alt={integration.name}/>
                <CardBody>
                  <CardTitle className="text-center" tag="h5">{integration.name}</CardTitle>
                  {
                    integration.authorised ?
                      <Button className="d-block mx-auto"
                        onClick={() => handleDeAuthorise('/accounts/social/connections/', integration.allauth_id)}
                        color="danger">De-Authorise</Button> :
                      <Button className="d-block mx-auto"
                        onClick={handleAuthorise('/accounts/google/login/')}
                        color="primary">Authorise</Button>
                  }
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </MainLayout>
  );
}
;

export default Integrations;