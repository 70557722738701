import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, FormGroup, Input, Button, Col } from 'reactstrap';

const AddActionCard = ({ onAddCard }) => {

  const [action, setAction] = useState('Generate');

  const handleSelectChange = (event) => {
    setAction(event.target.value);
  };

  const handleAddCard = () => {
    onAddCard(action);
  };

  return (
    <Col>
      <Card style={{ marginLeft: 0 }}>
        <CardBody className="text-center">
          <p>Select an action:</p>
          <FormGroup>
            <Input type="select" id="action-select" onChange={handleSelectChange}>
              <option value="Generate">Generate</option>
              <option value="Extract">Extract</option>
              <option value="Custom">Custom</option>
              <option value="Classify">Classify</option>
            </Input>
          </FormGroup>
          <Button color="primary" onClick={handleAddCard}>Go</Button>
        </CardBody>
      </Card>
    </Col >
  );
};

AddActionCard.propTypes = {
  onAddCard: PropTypes.func.isRequired,
};

export default AddActionCard;
