import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/main.css';
import './styles/homepage.css';
import './styles/sidebar.css';

import Login from './components/Authentication/Login';
import Logout from './components/Authentication/Logout';
import AccountDetails from './components/Accounts/Account';
import ListBots from './components/Chatbots/ListBots';
import Bot from './components/Chatbots/Bot';
import CreateBot from './components/Chatbots/CreateBot';
import Playground from './components/Chatbots/Playground';
import Integrations from './components/Integrations/Integrations';
import IntegrationDetails from './components/Integrations/IntegrationDetails';
import ListWorkflows from './components/Workflows/ListWorkflows';
import WorkflowDetails from './components/Workflows/WorkflowDetails';
import Metrics from './components/Metrics/Metrics';
import CreateWorkflow from './components/Workflows/CreateWorkflow';
import CreateWorkflowV2 from './components/Workflows/CreateWorkflowV2';
import Homepage from './components/Homepage/Homepage';
import Onboarding from './components/Onboarding/Onboarding';
import Subscription from './components/Subscription/Subscription';

import { AuthProvider } from 'contexts/AuthContext';
import PasswordReset from './components/Accounts/PasswordReset';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/logout' element={<Logout />} />
          <Route path='/' element={<Homepage />} />
          <Route path='/bots' element={<ListBots />} />
          <Route path='/bots/:id' element={<Bot />} />
          <Route path='/bots/create' element={<CreateBot />} />
          <Route path='/playground/:id' element={<Playground />} />
          <Route path='/account' element={<AccountDetails />} />
          <Route path='/integrations/' element={<Integrations />} />
          <Route path='/integrations/:id' element={<IntegrationDetails />} />
          <Route path='/workflows/' element={<ListWorkflows />} />
          <Route path='/workflows/:id' element={<WorkflowDetails />} />
          <Route path='/workflows/card' element={<CreateWorkflowV2 />} />
          <Route path='/workflows/create' element={<CreateWorkflow />} />
          <Route path='/metrics/' element={<Metrics />} />
          <Route path="/PasswordReset" element={<PasswordReset />} />
          <Route path='/onboarding' element={<Onboarding />} />
          <Route path='/subscription' element={<Subscription />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
