import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_DJANGO_HOST}/api/v1/`,
  timeout: 3600000,
});

// Function that will be called to refresh authorization
const refreshAuthLogic = failedRequest => axios.post(`${process.env.REACT_APP_DJANGO_HOST}/api/v1/token/refresh/`, {
  refresh: localStorage.getItem('refreshToken')
}).then(tokenRefreshResponse => {
  const { access, refresh } = tokenRefreshResponse.data;
  localStorage.setItem('accessToken', access);
  localStorage.setItem('refreshToken', refresh);
  failedRequest.response.config.headers['Authorization'] = 'Bearer ' + access;
  return Promise.resolve();
}).catch(error => {
  // Handle failed refresh here (e.g., redirect to login)
  window.location.href = '/login';
  return Promise.reject(error);
});

// Apply the interceptor to the axios instance
createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic);

axiosInstance.interceptors.request.use(request => {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    request.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return request;
}, error => {
  console.error('Request setup error:', error);
  return Promise.reject(error);
});

export default axiosInstance;
