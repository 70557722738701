import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axiosService from 'utils/axiosService';
import WorkflowForm from 'components/Workflows/WorkflowForm';
import MainLayout from 'components/MainLayout';
import {toast} from 'react-toastify';

const WorkflowDetails = () => {
  const [workflowData, setWorkflowData] = useState(null);
  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  useEffect(() => {
    async function fetchWorkflowDetails() {
      try {
        const response = await axiosService.get(`/workflows/${id}/`);
        setWorkflowData(response.data);
        setLoading(false);
      } catch (error) {
        toast.error('An error occurred while reading integration details.');
        setLoading(false);
      }
    }

    fetchWorkflowDetails();
  }, [id]);

  if (loading) return <p>Loading...</p>;

  return (
    <MainLayout title="Workflow Details" header="Workflow Details">
      <WorkflowForm data={workflowData} />
    </MainLayout>
  );
};

export default WorkflowDetails;
