import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';
import { AuthContext } from 'contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import axios from 'utils/axiosService';
import Onboarding from 'components/Onboarding/Onboarding';

function MainLayout({ title, header, footer, children }) {
  const navigate = useNavigate();
  const { authToken } = useContext(AuthContext);

  useEffect(() => {
    if (!authToken) {
      navigate('/login');
    }
  }, [authToken, navigate]);

  const [showOnboarding, setShowOnboarding] = useState(false);

  useEffect(() => {
    axios.get('/account/')
      .then(response => {
        if (response.data.user.user_details === null) {
          setShowOnboarding(true);
        } else {
          setShowOnboarding(false);
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }, []);

  if (showOnboarding) {
    return <Onboarding />;
  } else {
    return (
      <>
        <Helmet>
          <meta charset="UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta httpEquiv="Cross-Origin-Opener-Policy" content="same-origin-allow-popups" />

          <title>{title + ' - NeuroPlug' || 'NeuroPlug'}</title>
          <link rel="icon" href={process.env.PUBLIC_URL + '/favicon.ico'} />
          <script src="https://code.jquery.com/jquery-3.6.0.min.js"></script>
          <script src="https://cdn.jsdelivr.net/npm/sweetalert2@11"></script>
          <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
        </Helmet>

        <Container fluid={true} style={{ backgroundColor: '#FFFCFC' }}>
          <Row gx="0">
            <Col gx="0" md="2" className="no-padding">
              <Sidebar />
            </Col>

            <Col md="10">
              <Header content={header} style={{ height: '64px' }} />
              <div className={'pt-2'}>
                {children}
                <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
              </div>
              <Footer content={footer} />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

MainLayout.propTypes = {
  title: PropTypes.string,
  header: PropTypes.string,
  footer: PropTypes.string,
  children: PropTypes.node,
};

export default MainLayout;
