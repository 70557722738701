import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axiosInstance from 'utils/axiosService';
import { Container, Row, Col, Card, CardBody, CardImg, CardTitle, Button } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import GoogleDriveSearch from './GoogleDriveSearch';

const File = ({ file, selectFile }) => (
  <Col md="1" className="mb-3" style={{width: '12%'}}>
    <Card className={'google-drive-navigator-card cursor-pointer border-0'} onClick={() => selectFile(file.id, file.name)} title={file.name} altText={file.name}>
      <CardImg className={'p-1'} top src={file.iconLink} alt={file.name} />
      <CardBody className="p-2">
        <CardTitle tag="div" className="truncate-text m-0">{file.name}</CardTitle>
      </CardBody>
    </Card>
  </Col>
);

const Folder = ({ folder, navigate }) => (
  <Col md="1" className="mb-3" style={{width: '12%'}}>
    <Card className={'google-drive-navigator-card cursor-pointer border-0'} onClick={() => navigate(folder.id)} title={folder.name}>
      <CardImg top width="100%" src={folder.iconLink} alt={folder.name} />
      <CardBody className="p-2">
        <CardTitle tag="div" className="truncate-text m-0">{folder.name}</CardTitle>
      </CardBody>
    </Card>
  </Col>
);


const GoogleDriveNavigator = ({ onFileSelect, supportedFormats }) => {
  const [data, setData] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [path, setPath] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [previousTokens, setPreviousTokens] = useState([]);

  const fetchData = async (pageToken = null, searchTerm = null) => {
    try {
      let response = null;
      if (searchTerm) {
        response = await axiosInstance.get(`integrations/google/list-files?search_term=${searchTerm}${pageToken ? `&page_token=${pageToken}` : ''}`);
      } else {
        response = await axiosInstance.get(`integrations/google/list-files${pageToken ? `?page_token=${pageToken}` : ''}`);
      }

      setData(response.data.data);
      setCurrentData(response.data.data);
      setNextToken(response.data.nextPageToken);
    } catch (error) {
      console.error('Error fetching Google Drive data:', error);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData(previousTokens[previousTokens.length - 1]);
  }, [previousTokens]);

  const handleNext = () => {
    const oldNextToken = nextToken;
    setPreviousTokens(prevTokens => [...prevTokens, oldNextToken]);
  };

  const handlePrevious = () => {
    const newPreviousTokens = [...previousTokens];
    newPreviousTokens.length > 0 ? newPreviousTokens.pop() : null;
    setPreviousTokens(newPreviousTokens);
  };

  const navigate = (folderId) => {
    const folder = findFolderById(data, folderId);
    if (folder && folder.children) {
      setCurrentData(folder.children);
      setPath(prevPath => [...prevPath, folderId]);
    }
  };

  const selectFile = (fileId, fileName) => {
    const file = currentData.find(f => f.id === fileId);

    if (supportedFormats.includes(file.mimeType)) {
      if (onFileSelect) {
        onFileSelect(fileId, fileName);
      }
    } else {
      toast.error('This file type is currently unsupported.');
    }
  };

  const goBack = () => {
    if (path.length === 0) return;

    const newPath = [...path];
    newPath.pop();
    if (newPath.length === 0) {
      setCurrentData(data);
    } else {
      const parentFolder = findFolderById(data, newPath[newPath.length - 1]);
      setCurrentData(parentFolder.children);
    }
    setPath(newPath);
  };

  const findFolderById = (list, id) => {
    for (let item of list) {
      if (item.id === id) return item;
      if (item.mimeType === 'application/vnd.google-apps.folder' && item.children) {
        const found = findFolderById(item.children, id);
        if (found) return found;
      }
    }
    return null;
  };

  return (
    <Container>
      <GoogleDriveSearch onSearch={fetchData} />
      {path.length > 0 && (
        <Row className="mb-3">
          <Col md="12">
            <Button onClick={goBack}>Back</Button>
          </Col>
        </Row>
      )}
      <Row>
        {currentData.map(item =>
          item.mimeType === 'application/vnd.google-apps.folder' ?
            <Folder key={item.id} folder={item} navigate={navigate} /> :
            <File key={item.id} file={item} selectFile={selectFile}/>
        )}
      </Row>
      <Row>
        <Col md="12" className="text-center">
          <Button style={{marginRight:'10px'}} onClick={handlePrevious} disabled={previousTokens.length === 0}>Previous Page</Button>
          <Button onClick={handleNext} disabled={!nextToken}>Next Page</Button>
        </Col>
      </Row>
    </Container>
  );
};

File.propTypes = {
  file: PropTypes.object,
  selectFile: PropTypes.func.isRequired,
};

Folder.propTypes = {
  folder: PropTypes.object,
  navigate: PropTypes.func.isRequired,
};

GoogleDriveNavigator.propTypes = {
  onFileSelect: PropTypes.func.isRequired,
  supportedFormats: PropTypes.array.isRequired,
};


export default GoogleDriveNavigator;
