import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import axios from 'utils/axiosService';
import MainLayout from 'components/MainLayout';
import { Container, Spinner, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import Swal from 'sweetalert2';
import 'styles/animations.css';
import 'styles/AccountDetails.css';

const AccountDetails = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [sector, setSector] = useState('');
  const [token, setToken] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get('/account/', {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        const { token, user } = response.data;
        setUserData(user);

        setFirstName(user.first_name);
        setLastName(user.last_name);
        setEmail(user.email);
        setUserName(user.username);
        setToken(token);

        if (user.user_details) {
          setCompanyName(user.user_details.company_name || '');
          setSector(user.user_details.sector || '');
        } else {
          setCompanyName('');
          setSector('');
        }

        setLoading(false);
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'An unexpected error occurred. Please try again.',
        });
        setLoading(false);
      }
    };
    fetchUserData();
  }, []);

  const hasChanges = () => {
    return (
      firstName !== userData.first_name ||
            lastName !== userData.last_name ||
            userName !== userData.username ||
            email !== userData.email ||
            companyName !== userData.company_name ||
            sector !== userData.sector
    );
  };

  const handleSaveChanges = async () => {
    if (hasChanges()) {
      try {
        await axios.put('account/update-account/', {
          first_name: firstName,
          last_name: lastName,
          email: email,
          username: userName,
          user_details: {
            company_name: companyName,
            sector: sector,
          },
        });
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Account details updated successfully!',
        });
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'An error occurred while updating your account. Please try again.',
        });
      }
    }
  };

  const handleRegenerateToken = async () => {
    try {
      Swal.fire({
        title: 'Confirm Token Regeneration',
        text: 'Are you sure you want to regenerate your API token?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await axios.post('account/renew-token/');
          const newToken = response.data.token;
          setToken(newToken);
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'API Token updated successfully!',
          });
        }
      });
    } catch (error) {
      console.error('Error regenerating token:', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An error occurred while regenerating your API token. Please try again.',
      });
    }
  };

  const handleResetPassword = async () => {
    Swal.fire({
      title: 'Confirm Password Reset',
      text: 'Are you sure you want to reset your password?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then(async (result) => {
      if (result.isConfirmed) {
        navigate('/PasswordReset');
      }
    });
  };

  return (
    <MainLayout title="Account" header="Account Details">
      <Container>
        {loading ? (
          <div className="loading-spinner-container">
            <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />
          </div>
        ) : (
          <>
            <a href={'/subscription'}>Subscription Details</a>
            <Form>
              <FormGroup>
                <Label for="userName">Username</Label>
                <Input
                  type="text"
                  id="userName"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="firstName">First Name</Label>
                <Input
                  type="text"
                  id="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="lastName">Last Name</Label>
                <Input
                  type="text"
                  id="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="companyName">Company Name</Label>
                <Input
                  type="text"
                  id="companyName"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="industry">Sector</Label>
                <Input
                  type="text"
                  id="sector"
                  value={sector}
                  onChange={(e) => setSector(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label>API Token</Label>
                <Input
                  type="text"
                  value={token}
                  disabled />
              </FormGroup>
              <Button onClick={handleRegenerateToken}>Regenerate Token</Button>
              <hr></hr>
              <div>
                <Button className='button-margin-right' color="success" onClick={handleSaveChanges}>
                Save Changes
                </Button>
              </div>
            </Form>
            <Button className='mt-2' color="primary" onClick={handleResetPassword}>
            Reset Password
            </Button>
          </>
        )}
      </Container>
    </MainLayout >
  );
};

export default AccountDetails;
