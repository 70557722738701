import React, { useState } from 'react';
import { Card, CardBody, CardTitle, Button, Input, Row, Col, Collapse } from 'reactstrap';
import axios from 'utils/axiosService';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import FileUploadComponent from '../../SharedComponents/FileUpload/FileUploadComponent';
import ActionOutputDownload from '../../SharedComponents/ActionOutputDownload/ActionOutputDownload';
import { downloadCsv } from '../../../utils/fileDownloadService';
import { FaTimes, FaCaretDown, FaCaretRight } from 'react-icons/fa';

const CustomCard = ({ id, isButtonDisabled, onRemoveCard, onFileUpload, onFileDelete, files }) => {
  const [prompt, setPrompt] = useState('');
  const [outputFormat, setOutputFormat] = useState('.csv');
  const [isDataCollapsed, setIsDataCollapsed] = useState(false); // Open by default
  const [isPromptCollapsed, setIsPromptCollapsed] = useState(false); // Open by default

  const handleDownload = async () => {
    if (!prompt) {
      toast.error('Please enter a prompt before downloading.');
      return;
    }
    const formData = new FormData();
    formData.append('prompt', prompt);
    formData.append('output_format', outputFormat.slice(1));
    if (files.length > 0) {
      formData.append('file_info', JSON.stringify(files));
    }
    try {
      const response = await axios.post('/extract/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob'
      });
      const reader = new FileReader();
      reader.onload = function(event) {
        const data = event.target.result;
        downloadCsv(data, `file${outputFormat}`, 'text/csv');
      };
      reader.readAsText(response.data);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred. Please try again.' + error,
      });
    }
  };

  return (
    <Col>
      <Card className="shadow mx-0">
        <CardBody>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <CardTitle tag="h3" className="mx-0 mb-2">Custom</CardTitle>
            <Button className="pb-4"
              style={{ background: 'none', border: 'none', padding: 0 }}
              onClick={() => onRemoveCard(id)}
            >
              <FaTimes size={20} color="black" />
            </Button>
          </div>
          <h5 className="mb-0 mt-2" onClick={() => setIsDataCollapsed(!isDataCollapsed)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
            {isDataCollapsed ? <FaCaretRight className="mr-2" /> : <FaCaretDown className="mr-2" />}
            Selected Data
          </h5>
          <Collapse isOpen={!isDataCollapsed}>
            <div className="m-0">
              <FileUploadComponent
                onFileUpload={onFileUpload}
                buttonText="+Add Data"
                buttonColor="primary"
                allowMultiple={true}
                files={files}
                onDeleteFile={onFileDelete}
                id={id}
                action="Custom"
              />
            </div>
          </Collapse>
          <hr />
          <Row className="m-0">
            <h5 className="mx-0 mb-2 p-0" onClick={() => setIsPromptCollapsed(!isPromptCollapsed)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
              {isPromptCollapsed ? <FaCaretRight className="mr-2" /> : <FaCaretDown className="mr-2" />}
              Prompt
            </h5>
            <Collapse isOpen={!isPromptCollapsed} className="w-100 m-0 p-0">
              <Input className="m-0"
                type="textarea"
                id="custom-prompt"
                rows='10'
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                placeholder="Enter your custom prompt here"
              />
            </Collapse>
          </Row>
          <hr />
          <ActionOutputDownload
            isButtonDisabled={isButtonDisabled}
            handleDownload={handleDownload}
            outputFormat={outputFormat}
            setOutputFormat={setOutputFormat}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

CustomCard.propTypes = {
  id: PropTypes.number.isRequired, // Ensure this matches the ID type you pass
  isButtonDisabled: PropTypes.bool.isRequired,
  onRemoveCard: PropTypes.func.isRequired,
  onFileUpload: PropTypes.func.isRequired,
  onFileDelete: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
};

export default CustomCard;
