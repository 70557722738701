import React from 'react';
import { IoMdTrash } from 'react-icons/io';
import PropTypes from 'prop-types';

import 'styles/trashIcon.css';

const TrashIcon = ({ objectId, onTrashClick }) => {
  return (
    <div className="trash-icon-container">
      <a
        id="deleteSingle"
        onClick={(event) => {
          event.stopPropagation();
          onTrashClick(objectId);
        }}
      >
        <IoMdTrash className="text-red" />
      </a>
    </div >
  );
};

TrashIcon.propTypes = {
  objectId: PropTypes.number.isRequired,
  onTrashClick: PropTypes.func.isRequired,
};

export default TrashIcon;