import React, { useState, useEffect } from 'react';
import axios from 'utils/axiosService';
import { Container, Button, Form, FormGroup, Label, Input, Table } from 'reactstrap';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import MainLayout from 'components/MainLayout';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const Bot = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [bot, setBot] = useState({});
  const [embeddings, setEmbeddings] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const successMessage = queryParams.get('success');

  const handleEmbeddingChange = (index, field, value) => {
    const newEmbeddings = [...embeddings];
    newEmbeddings[index][field] = value;
    setEmbeddings(newEmbeddings);
  };

  const removeEmbedding = (index) => {
    const newEmbeddings = embeddings.filter((_, i) => i !== index);
    setEmbeddings(newEmbeddings);
  };

  useEffect(() => {
    if (successMessage) {
      toast.success('Bot created successfully!');
    }
  }, [successMessage]);

  useEffect(() => {
    const fetchBotData = async () => {
      try {
        const response = await axios.get(`/bots/${id}/`);
        setBot(response.data);

        if (response.data.embeddings) {
          setEmbeddings(JSON.parse(response.data.embeddings));
        }
      } catch (error) {
        console.error('An error occurred while fetching bot data:', error);
      }
    };
    fetchBotData();
  }, [id]);

  const handleSubmit = async (event) => {
    event.preventDefault(); // prevent page refresh

    // Convert the embeddings array back to a JSON string
    const embeddingsString = JSON.stringify(embeddings);

    // Update the bot object with the new embeddings string
    const updatedBot = { ...bot, embeddings: embeddingsString };

    try {
      await axios.put(`/bots/${id}/`, updatedBot);

      toast.success('Changes saved successfully!');
    } catch (error) {
      console.error('An error occurred while saving bot data:', error);
      toast.error('An error occurred while saving.');
    }
  };

  return (
    <MainLayout title="Bot Details" header="Bot Details">
      <Container>
        <Form onSubmit={handleSubmit}>
          <FormGroup className="mb-3">
            <Label for="name"><strong>Bot Name:</strong></Label>
            <Input
              type="textarea"
              id="name"
              name="name"
              required
              value={bot.name}
              onChange={(e) => setBot({ ...bot, name: e.target.value })}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <Label for="description"><strong>Bot Description:</strong></Label>
            <Input
              type="textarea"
              id="description"
              name="description"
              required
              value={bot.description}
              onChange={(e) => setBot({ ...bot, description: e.target.value })}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <Label for="embed_code"><strong>Embed Code:</strong></Label>
            <Input type="textarea" id="embed_code" readOnly defaultValue={bot.embed_code} />
          </FormGroup>
          <FormGroup className="mb-3">
            <Label for="initial_prompt"><strong>Initial Prompt:</strong></Label>
            <Input
              type="textarea"
              id="initial_prompt"
              name="initial_prompt"
              required
              value={bot.initial_prompt}
              onChange={(e) => setBot({ ...bot, initial_prompt: e.target.value })}
              rows="10"
            />
          </FormGroup>

          <Table>
            <thead>
              <tr>
                <th>Title</th>
                <th>Value</th>
                <th></th>
              </tr>
            </thead>
            <tbody id="embeddings">
              {embeddings.map((embedding, index) => (
                <tr key={index}>
                  <td><Input type="text" className="form-control" name={`title${index + 1}`} value={embedding.title} onChange={e => handleEmbeddingChange(index, 'title', e.target.value)} /></td>
                  <td><Input type="text" className="form-control" name={`value${index + 1}`} value={embedding.value} onChange={e => handleEmbeddingChange(index, 'value', e.target.value)} /></td>
                  <td><Button color="danger" onClick={() => removeEmbedding(index)}>Remove</Button></td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Button color="primary" onClick={() => setEmbeddings([...embeddings, { title: '', value: '' }])}>Add Row</Button>
          <br /><br />
          <Button color="secondary" onClick={() => navigate(`/playground/${id}`)}>Preview in Playground</Button>
          <Button color="success" type="submit">Save</Button>
        </Form>
      </Container>
    </MainLayout>
  );
};

export default Bot;
