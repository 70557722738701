import React, { useState } from 'react';
import { Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';
import PropTypes from 'prop-types';

const ActionOutputDownload = ({ isButtonDisabled, handleDownload, outputFormat, setOutputFormat }) => {
  const [isDownloadEnabled, setIsDownloadEnabled] = useState(true);

  return (
    <>
      <Row className="mt-1 mb-0">
        <FormGroup className="d-flex align-items-center m-0">
          <Col md={9}>
            <FormGroup switch>
              <Input
                type="switch"
                id="download-switch"
                checked={isDownloadEnabled}
                onChange={(e) => setIsDownloadEnabled(e.target.checked)}
              />
              <Label for="download-switch" className="mr-2 mb-0">Download Action Output</Label>
            </FormGroup>
          </Col>
        </FormGroup>
      </Row>
      {isDownloadEnabled && (
        <>
          <Row className="m-0">
            <FormGroup className="d-flex align-items-center">
              <Col md={3}>
                <Label className="mr-2 mb-0" for="output-format">Output as:</Label>
              </Col>
              <Col md={9}>
                <Input className="mx-0"
                  type="select"
                  id="output-format"
                  value={outputFormat}
                  onChange={(e) => setOutputFormat(e.target.value)}
                >
                  <option value=".xlsx">.xlsx</option>
                  <option value=".csv">.csv</option>
                  <option value=".pdf">.pdf</option>
                </Input>
              </Col>
            </FormGroup>
          </Row>
          {isButtonDisabled ? (
            <Button color="primary" onClick={handleDownload}>Download</Button>
          ) : (
            <Button color="warning" disabled>Download</Button>
          )}
        </>
      )}
    </>
  );
};

ActionOutputDownload.propTypes = {
  isButtonDisabled: PropTypes.bool.isRequired,
  handleDownload: PropTypes.func.isRequired,
  outputFormat: PropTypes.string.isRequired,
  setOutputFormat: PropTypes.func.isRequired,
};

export default ActionOutputDownload;
