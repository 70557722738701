import React, { useState } from 'react';
import { Card, CardBody, CardTitle, Button, Col, Collapse } from 'reactstrap';
import axios from 'utils/axiosService';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import FileUploadComponent from '../../SharedComponents/FileUpload/FileUploadComponent';
import ActionOutputDownload from '../../SharedComponents/ActionOutputDownload/ActionOutputDownload';
import ClassifyCardFieldParameters from './ClassifyCardFieldParameters';
import { FaTimes, FaCaretDown, FaCaretRight } from 'react-icons/fa';
import { downloadCsv } from '../../../utils/fileDownloadService';

const ClassifyCard = ({ id, isButtonDisabled, onRemoveCard, onFileUpload, onFileDelete, files }) => {
  const [categories, setCategories] = useState([{ name: '', description: '' }]);
  const [outputFormat, setOutputFormat] = useState('.csv');
  const [categoryErrors, setCategoryErrors] = useState([]);
  const [isDataCollapsed, setIsDataCollapsed] = useState(false);
  const [isFieldParamsCollapsed, setIsFieldParamsCollapsed] = useState(false);

  const addCategory = () => {
    setCategories([...categories, { name: '', description: '' }]);
  };

  const validateCategories = () => {
    const errors = categories.map(category => !category.name || !category.description);
    setCategoryErrors(errors);
    return !errors.some(error => error); // Return true if no errors
  };

  const handleDownload = async () => {
    if (!validateCategories()) {
      toast.error('Please fill out all fields before downloading.');
      return;
    }

    const formData = new FormData();

    let parameters = 'Your job is to classify entries into predefined categories. The categories and their descriptions are as follows:\n';
    categories.forEach((category, index) => {
      parameters += (index + 1) + '. ' + category.name + ': ' + category.description + '\n';
    });

    let prompt = parameters + '\nA user has provided the following data to be categorized:\n';

    if (files.length > 0) {
      formData.append('file_info', JSON.stringify(files));
    }

    formData.append('output_format', outputFormat.slice(1));

    formData.append('prompt', prompt);

    try {
      const response = await axios.post('/extract/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob'
      });

      const reader = new FileReader();
      reader.onload = function(event) {
        const data = event.target.result;
        downloadCsv(data, `file${outputFormat}`, 'text/csv');
      };

      reader.readAsText(response.data);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred. Please try again.' + error,
      });
    }
  };

  return (
    <Col>
      <Card className="shadow mx-0">
        <CardBody>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <CardTitle tag="h3" className="mx-0 mb-2">Classify</CardTitle>
            <Button className="pb-4"
              style={{ background: 'none', border: 'none', padding: 0 }}
              onClick={() => onRemoveCard(id)}
            >
              <FaTimes size={20} color="black" />
            </Button>
          </div>
          <h5 className="mb-0 mt-2" onClick={() => setIsDataCollapsed(!isDataCollapsed)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
            {isDataCollapsed ? <FaCaretRight className="mr-2" /> : <FaCaretDown className="mr-2" />}
            Selected Data
          </h5>
          <Collapse isOpen={!isDataCollapsed}>
            <div className="m-0">
              <FileUploadComponent
                onFileUpload={onFileUpload}
                buttonText="+Add Data"
                buttonColor="primary"
                allowMultiple={true}
                files={files}
                onDeleteFile={onFileDelete}
                id={id}
                action="Classify"
              />
            </div>
          </Collapse>
          <hr />
          <h5 className="mb-0 mt-2" onClick={() => setIsFieldParamsCollapsed(!isFieldParamsCollapsed)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
            {isFieldParamsCollapsed ? <FaCaretRight className="mr-2" /> : <FaCaretDown className="mr-2" />}
            Field Parameters
          </h5>
          <Collapse isOpen={!isFieldParamsCollapsed}>
            <ClassifyCardFieldParameters categories={categories} setCategories={setCategories} addCategory={addCategory} categoryErrors={categoryErrors} />
          </Collapse>
          <hr />
          <ActionOutputDownload
            isButtonDisabled={isButtonDisabled}
            handleDownload={handleDownload}
            outputFormat={outputFormat}
            setOutputFormat={setOutputFormat}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

ClassifyCard.propTypes = {
  id: PropTypes.number.isRequired, // Ensure this matches the ID type you pass
  isButtonDisabled: PropTypes.bool.isRequired,
  onRemoveCard: PropTypes.func.isRequired,
  onFileUpload: PropTypes.func.isRequired,
  onFileDelete: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
};

export default ClassifyCard;
