import React, { useState, useContext } from 'react';
import { Button, Form, FormGroup, Label, Input, Alert } from 'reactstrap';
import axios from 'utils/axiosService';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'contexts/AuthContext';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [visible, setVisible] = useState(false);

  const { setAuthToken } = useContext(AuthContext);
  const navigate = useNavigate();

  const onDismiss = () => setVisible(false);

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      const response = await axios.post('login/', {
        username: username,
        password: password
      }, {
        withCredentials: true,
      });
      if (response.status === 200) {
        setAuthToken(response.data);
        navigate('/');
      }
    } catch (error) {
      setVisible(true);
    }
  };

  return (
    <>
      <div className="container" style={{height: '100vh'}}>
        <div className="h-100 d-flex justify-content-center align-items-center">
          <div className="mdc-card col-6">
            <Form onSubmit={handleSubmit}>
              <FormGroup className="mdc-text-field">
                <Label for="username">USERNAME</Label>
                <Input type="text" name="username" id="username" value={username} onChange={e => setUsername(e.target.value)} />
              </FormGroup>
              <FormGroup className="mdc-text-field">
                <Label for="password">PASSWORD</Label>
                <Input type="password" name="password" id="password" value={password} onChange={e => setPassword(e.target.value)} />
              </FormGroup>
              <Button color="primary" block type="submit">
                LOGIN
              </Button>
            </Form>
          </div>
        </div>
      </div>
      <Alert color="danger" isOpen={visible} toggle={onDismiss}>
        Login Failed
      </Alert>
    </>
  );
};

export default Login;
