import React, { useState, useRef, useEffect } from 'react';
import { Container, Button, Form, FormGroup, Label, Input, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import MainLayout from 'components/MainLayout';
import axios from 'utils/axiosService';
import Swal from 'sweetalert2';

const CreateBot = () => {
  const { handleSubmit, control, setValue } = useForm();
  const [errorMessage, setErrorMessage] = useState(null);
  const [activeTab, setActiveTab] = useState('1');
  const textareaRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    let embeddingsType = '';
    switch (activeTab) {
    case '1':
      embeddingsType = 'file_upload';
      break;
    case '2':
      embeddingsType = 'website_scrape';
      break;
    case '3':
      embeddingsType = 'text';
      break;
    default:
      break;
    }
    setValue('embeddings_type', embeddingsType);
  }, [activeTab, setValue]);

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();

      switch (activeTab) {
      case '1': // file upload
        if (data.embeddingsFile) {
          formData.append('embeddings_file', data.embeddingsFile);
        }
        break;
      case '2': // website scrape
        formData.append('embeddings', data.website);
        break;
      case '3': // text
        formData.append('embeddings', data.freeText);
        break;
      default:
        break;
      }

      for (const key in data) {
        if (key !== 'embeddingsFile' && key !== 'website' && key !== 'freeText') {
          formData.append(key, data[key]);
        }
      }

      const response = await axios.post('/bots/create/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const botId = response.data.id;
      navigate(`/bots/${botId}?success=true`);
    } catch (error) {

      const duplicateNameErrorMessage = 'A bot with that name already exists';

      if (error.response.data.error === duplicateNameErrorMessage &&
        error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `${duplicateNameErrorMessage} 😢`,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'An unexpected error occurred. Please try again.',
        });

        console.log('Error submitting form:', error);
        setErrorMessage(error.message);
      }
    }
  };

  const handleButtonClick = (text) => {
    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const value = textarea.value;
    const newValue = value.substring(0, start) + text + value.substring(end);

    setValue('initial_prompt', newValue);
  };

  return (
    <MainLayout title="Create New Bot" header="Build New Bot">
      <Container>
        <div>
          <Form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
            <FormGroup>
              <Label for="bot_name">Bot Name*</Label>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) => <Input {...field} type="text" id="bot_name" />}
              />
            </FormGroup>

            <FormGroup>
              <Label for="description">Bot Description*</Label>
              <Controller
                name="description"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) => <Input {...field} type="text" id="description" />}
              />
            </FormGroup>

            <FormGroup>
              <Label for="initial_prompt">Initial Prompt</Label>
              <br />
              <Button className="me-1 mb-1" onClick={() => handleButtonClick('{currentDateTime}')}>Current Date & Time</Button>
              <Button className="me-1 mb-1" onClick={() => handleButtonClick('{business_name}')}>Business Name</Button>
              <Button className="mb-1" onClick={() => handleButtonClick('{business_sector}')}>Business Sector</Button>
              <Controller
                name="initial_prompt"
                control={control}
                defaultValue={`You are a livechat bot for a {business_sector} business named {business_name}.

The current date and time are {currentDateTime}.

Your job is to answer the customer's questions as if you were someone working in the {business_sector} industry.

Your response should go in 'message_for_user'.

If at any point you need help, put your request in 'message_for_owner' and let the user know in 'message_for_user' that you are unable to help further but have passed their query on to the appropriate team.

Only respond in JSON format. Make sure your response contains nothing but JSON.

Answer as truthfully as possible.

If you don't know the answer to the question say 'Sorry, I don't know the answer to that question'.`}
                rules={{ required: true }}
                render={({ field }) =>
                  <Input
                    {...field}
                    innerRef={textareaRef}
                    type="textarea"
                    id="initial_prompt"
                    rows="10"
                  />
                }
              />
            </FormGroup>

            <Nav tabs className="pt-5">
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => { toggle('1'); }}
                >
                  File Upload
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => { toggle('2'); }}
                >
                  Website Scrape
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => { toggle('3'); }}
                >
                  Free Text
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={activeTab} className="py-4">
              <TabPane tabId="1">
                <FormGroup>
                  <Controller
                    name="embeddingsFile"
                    control={control}
                    defaultValue={null}
                    render={({ field: { onChange, ref } }) => (
                      <Input
                        type="file"
                        id="file-upload"
                        innerRef={ref}
                        onChange={(e) => {
                          onChange(e.target.files[0]); // Use the first selected file
                        }}
                      />
                    )}
                  />
                </FormGroup>
              </TabPane>
              <TabPane tabId="2">
                <FormGroup>
                  <Controller
                    name="website"
                    control={control}
                    defaultValue=""
                    render={({ field }) => <Input {...field} type="text" id="website-url" placeholder="www.test.com" />}
                  />
                </FormGroup>
              </TabPane>
              <TabPane tabId="3">
                <FormGroup>
                  <Controller
                    name="freeText"
                    control={control}
                    defaultValue=""
                    render={({ field }) => <Input {...field} type="textarea" id="free-text" />}
                  />
                </FormGroup>
              </TabPane>
            </TabContent>

            <Button type="submit" color="primary">Build</Button>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </Form>
        </div>
      </Container>
    </MainLayout>
  );
};

export default CreateBot;
