import React from 'react';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { FaTrash } from 'react-icons/fa';

const ExtractCardFieldParameters = ({ fields, setFields, addField, fieldErrors }) => {

  const handleChange = (index, event) => {
    const newFields = fields.map((field, i) => {
      if (i === index) {
        return { ...field, [event.target.name]: event.target.value };
      }
      return field;
    });
    setFields(newFields);
  };

  const handleRemove = (index) => {
    const newFields = fields.filter((_, i) => i !== index);
    setFields(newFields);
  };

  return (
    <>
      {fields.map((field, index) => (
        <Row key={index} className="mb-1">
          <Col>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h6 className="mt-2 subtitle"><strong>
                 Field {index + 1}
              </strong></h6>
              {fields.length > 1 && (
                <Button
                  style={{ background: 'none', border: 'none', padding: 0 }}
                  onClick={() => handleRemove(index)}
                >
                  <FaTrash size={14} color="red" />
                </Button>
              )}
            </div>
            <FormGroup className="d-flex align-items-center">
              <Col md={3}>
                <Label className="mr-2 mb-0" for={`name-${index}`}>Name:</Label>
              </Col>
              <Col md={9}>
                <Input className="mx-2 mt-1"
                  type="text"
                  id={`name-${index}`}
                  name="name"
                  placeholder="Name for the Field"
                  value={field.name}
                  onChange={(e) => handleChange(index, e)}
                  style={{ borderColor: fieldErrors[index] && !field.name ? 'red' : '' }}
                />
              </Col>
            </FormGroup>
            <FormGroup className="d-flex align-items-center">
              <Col md={3}>
                <Label className="mr-2 mb-0" for={`type-${index}`}>Type:</Label>
              </Col>
              <Col md={9}>
                <Input
                  className="mx-2"
                  type="select"
                  id={`type-${index}`}
                  name="type"
                  value={field.type}
                  onChange={(e) => handleChange(index, e)}
                  style={{ borderColor: fieldErrors[index] && !field.type ? 'red' : '' }}
                >
                  <option>Number</option>
                  <option>Text</option>
                </Input>
              </Col>
            </FormGroup>
            <FormGroup className="d-flex align-items-center">
              <Col md={3}>
                <Label className="mr-2 mb-0 mt-0" for={`description-${index}`}>Description:</Label>
              </Col>
              <Col md={9}>
                <Input className="mx-2 mt-0"
                  type="text"
                  id={`description-${index}`}
                  name="description"
                  placeholder="Describe what this data is..."
                  value={field.description}
                  onChange={(e) => handleChange(index, e)}
                  style={{ borderColor: fieldErrors[index] && !field.description ? 'red' : '' }}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
      ))}
      <Button color="primary" onClick={addField}>+ Add Field</Button>
    </>
  );
};

ExtractCardFieldParameters.propTypes = {
  fields: PropTypes.array.isRequired,
  setFields: PropTypes.func.isRequired,
  addField: PropTypes.func.isRequired,
  fieldErrors: PropTypes.array.isRequired, // Add the prop type
};

export default ExtractCardFieldParameters;
