import PropTypes from 'prop-types';
import GoogleDriveNavigator from './GoogleDriveNavigator';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/main.css';

const GoogleDocs = ({ onSave }) => {

  const handleSave = (file_id, file_name) => {
    if (onSave) {
      const integrationData = {
        integration_id: 3,
        file_id: file_id,
        file_name: file_name,
        format: 'string',
      };
      onSave(integrationData);
    }
  };

  return (
    <div className="d-flex flex-column align-items-start">
      <GoogleDriveNavigator onFileSelect={handleSave} supportedFormats={['application/vnd.google-apps.document']} />
    </div>
  );
};

GoogleDocs.propTypes = {
  onSave: PropTypes.func,
};

export default GoogleDocs;
