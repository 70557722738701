import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Container } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'contexts/AuthContext';
import { MdExitToApp } from 'react-icons/md';
import { userAllowed } from 'utils/authService';

const Sidebar = () => {
  const navigate = useNavigate();
  const { setAuthToken } = useContext(AuthContext);
  const location = useLocation();

  const sidebarElements = [
    { link: '/', icon: <img src={process.env.PUBLIC_URL + '/icons/home.svg'} alt="Home Icon" className="icon-style" />, text: 'Home', className: 'link-home', allowedRoles: ['admin'] },
    { link: '/workflows', icon: <img src={process.env.PUBLIC_URL + '/icons/workflows.svg'} alt="Workflows Icon" className="icon-style" />, text: 'Workflows', className: 'link-workflows', allowedRoles: ['admin'] },
    { link: '/workflows/card/', icon: <img src={process.env.PUBLIC_URL + '/icons/workflows.svg'} alt="Workflows V2 Icon" className="icon-style" />, text: 'Workflows V2', className: 'link-workflows', allowedRoles: ['admin'] },
    { link: '/bots', icon: <img src={process.env.PUBLIC_URL + '/icons/chatbots.svg'} alt="Chat Bots Icon" className="icon-style" />, text: 'Chatbots', className: 'link-chatbots', allowedRoles: ['admin'] },
    { link: '/metrics', icon: <img src={process.env.PUBLIC_URL + '/icons/metrics.svg'} alt="Metrics Icon" className="icon-style" />, text: 'Metrics & Usage', className: 'link-metrics', allowedRoles: ['admin'] },
    { link: '/integrations', icon: <img src={process.env.PUBLIC_URL + '/icons/integrations.svg'} alt="Integrations Icon" className="icon-style" />, text: 'Integrations', className: 'link-integrations', allowedRoles: ['admin'] },
    { link: '/account', icon: <img src={process.env.PUBLIC_URL + '/icons/account.svg'} alt="Account Icon" className="icon-style" />, text: 'Account', className: 'link-account', allowedRoles: ['admin'] },
  ];

  const logout = () => {
    localStorage.removeItem('token');
    setAuthToken(null);
    navigate('/login');
  };

  return (
    <Container className="float-start d-flex flex-column text-white vh-100 position-fixed col-md-2 sidebar-container">
      <img src={process.env.PUBLIC_URL + '/logo.png'} alt="NeuroPlug Logo" className="sidebar-logo" />
      {sidebarElements.map((item, index) => (
        userAllowed(item.allowedRoles) ? (
          <div key={index} className="d-flex sidebar-item">
            {item.icon}
            <Link to={item.link} className={`ms-2 text-white text-decoration-none sidebar-link ${item.className} ${location.pathname === item.link ? 'active' : ''}`}>{item.text}</Link>
          </div>
        ) : null
      ))}
      <div className="mt-auto">
        <Button color="link" className="text-white text-decoration-none" onClick={logout}>
          <MdExitToApp className="text-white" /> Logout
        </Button>
      </div>
    </Container>
  );
};

export default Sidebar;
