import WorkflowForm from 'components/Workflows/WorkflowForm';
import MainLayout from 'components/MainLayout';

const CreateWorkflow = () => {
  return (
    <MainLayout title="Create Workflow" header="Create Workflow">
      <WorkflowForm />
    </MainLayout>
  );
};

export default CreateWorkflow;

