import React, { useState } from 'react';
import { Card, CardBody, CardTitle, Button, Label, Input, Row, Col, FormGroup } from 'reactstrap';

const OutputFormat = {
  GoogleDoc: 'Google Document',
  Pdf: 'PDF'
};

const GenerateCard = () => {
  const [fields, setFields] = useState([{ category: '', tone: 'Number', length: '', description: '' }]);
  const [dataUploaded, setDataUploaded] = useState(false);
  const [outputFormat, setOutputFormat] = useState(OutputFormat.SPREADSHEET);

  const addField = () => {
    setFields([...fields, { category: '', tone: 'Number', length: '', description: '' }]);
  };

  const handleUpload = () => {
    setDataUploaded(true);
  };

  const handleChange = (index, event) => {
    const newFields = fields.map((field, i) => {
      if (i === index) {
        return { ...field, [event.target.name]: event.target.value };
      }
      return field;
    });
    setFields(newFields);
  };
  return (
    <Col>
      <Card style={{ marginLeft: 0 }}>
        <CardBody>
          <CardTitle tag="h5">Generate</CardTitle>
          {dataUploaded ? (
            <Button color="primary" onClick={handleUpload}>+ Add Data</Button> // Need to replace this with the output of the extract data...
          ) : (
            <div className="d-flex align-items-center">
              <Label className="mr-3">Extracted Data (XTO1)</Label>
              <Button className='d-block mx-auto' color="secondary" onClick={() => setDataUploaded(false)}>Replace</Button>
            </div>
          )}
          <hr />
          {fields.map((field, index) => (
            <Row key={index} className="mb-3">
              <h5 style={{ textDecoration: 'underline' }}><strong>Language Parameters {index + 1}</strong></h5>
              <br />
              <br />
              <Col>
                <FormGroup className="d-flex align-items-center">
                  <Col md={3}>
                    <Label className="mr-2 mb-0" for={`name-${index}`}>Category:</Label>
                  </Col>
                  <Col md={9}>
                    <Input className="mx-2"
                      type="text"
                      id={`name-${index}`}
                      name="name"
                      placeholder="Email"
                      value={field.name}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="d-flex align-items-center">
                  <Col md={3}>
                    <Label className="mr-2 mb-0" for={`tone-${index}`}>Tone:</Label>
                  </Col>
                  <Col md={9}>
                    <Input
                      className="mx-2"
                      type="select"
                      id={`tone-${index}`}
                      name="tone"
                      value={field.tone}
                      onChange={(e) => handleChange(index, e)}
                    >
                      <option value="casual">Casual</option>
                      <option value="semi-formal">Semi-formal</option>
                      <option value="formal">Formal</option>
                    </Input>
                  </Col>
                </FormGroup>
                <FormGroup className="d-flex align-items-center">
                  <Col md={3}>
                    <Label className="mr-2 mb-0" for={`length-${index}`}>Length:</Label>
                  </Col>
                  <Col md={3}>
                    <Input
                      className="mx-2"
                      type="select"
                      id={`length-comparison-${index}`}
                      name="length.comparison"
                      value={field.length.comparison}
                      onChange={(e) => handleChange(index, e)}
                    >
                      <option value="less than">Less than</option>
                      <option value="less than or equal to">Less than or equal to </option>
                      <option value="greater than">Greater than</option>
                    </Input>
                  </Col>
                  <Col md={3}>
                    <Input
                      className="mx-2"
                      type="number"
                      id={`length-value-${index}`}
                      name="length.value"
                      placeholder="Number"
                      value={field.length.value}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </Col>
                  <Col md={3}>
                    <Input
                      className="mx-2"
                      type="select"
                      id={`length-unit-${index}`}
                      name="length.unit"
                      value={field.length.unit}
                      onChange={(e) => handleChange(index, e)}
                    >
                      <option value="words">Words</option>
                      <option value="characters">Characters</option>
                      <option value="sentences">Sentences</option>
                    </Input>
                  </Col>
                </FormGroup>
                <FormGroup className="d-flex align-items-center">
                  <Col md={3}>
                    <Label className="mr-2 mb-0" for={`description-${index}`}>Description:</Label>
                  </Col>
                  <Col md={9}>
                    <Input className="mx-2"
                      type="text"
                      id={`description-${index}`}
                      name="description"
                      placeholder="Total Amount quoted on invoice"
                      value={field.description}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          ))}
          <Button color="primary" onClick={addField}>+ Add New Output</Button>
          <hr />
          <Row className="mt-3">
            <FormGroup className="d-flex align-items-center">
              <Col md={3}>
                <Label className="mr-2 mb-0" for="output-format">Output as:</Label>
              </Col>
              <Col md={9}>
                <Input className="mx-2"
                  type="select"
                  id="output-format"
                  value={outputFormat}
                  onChange={(e) => setOutputFormat(e.target.value)}
                >
                  <option value={OutputFormat.SPREADSHEET}>Spreadsheet</option>
                  <option value={OutputFormat.PDF}>PDF</option>
                  <option value={OutputFormat.CSV}>CSV</option>
                </Input>
              </Col>
            </FormGroup>
          </Row>
        </CardBody>
      </Card >
    </Col >
  );
};

export default GenerateCard;
