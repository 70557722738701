import { jwtDecode } from 'jwt-decode';

export const userAllowed = (allowedRoles) => {
  const token = localStorage.getItem('accessToken');
  if (!token) return false;

  const decodedToken = jwtDecode(token);
  const userRole = decodedToken.group;

  if (userRole === 'superadmin') {
    return true;
  }

  return allowedRoles.includes(userRole);
};