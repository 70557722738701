import React from 'react';
import PropTypes from 'prop-types';

const Footer = ({ content }) => {
  return (
    <>
      {content}
    </>
  );
};

Footer.propTypes = {
  content: PropTypes.node,
};

export default Footer;
