import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Card, CardBody, CardTitle, Button, Collapse, Col } from 'reactstrap';
import axios from 'utils/axiosService';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import FileUploadComponent from '../../SharedComponents/FileUpload/FileUploadComponent';
import ActionOutputDownload from '../../SharedComponents/ActionOutputDownload/ActionOutputDownload';
import ExtractCardFieldParameters from './ExtractCardFieldParameters';
import { downloadCsv } from '../../../utils/fileDownloadService';
import { FaTimes, FaCaretDown, FaCaretRight } from 'react-icons/fa';

const ExtractCard = forwardRef(({ id, isButtonDisabled, onRemoveCard, onFileUpload, onFileDelete, files }, ref) => {
  const [fields, setFields] = useState([{ name: '', type: 'Number', description: '' }]);
  const [outputFormat, setOutputFormat] = useState('.csv');
  const [fieldErrors, setFieldErrors] = useState([]);
  const [isDataCollapsed, setIsDataCollapsed] = useState(false); // Open by default
  const [isFieldParamsCollapsed, setIsFieldParamsCollapsed] = useState(false); // Open by default

  useImperativeHandle(ref, () => ({
    generatePrompt,
  }));

  const addField = () => {
    setFields([...fields, { name: '', type: 'Number', description: '' }]);
  };

  const validateFields = () => {
    const errors = fields.map(field => !field.name || !field.type || !field.description);
    setFieldErrors(errors);
    return !errors.some(error => error); // Return true if no errors
  };

  const generatePrompt = () => {
    let parameters = 'I need the following parameters extracted from the below text. Extract all associated rows, not just the first one:\n';
    fields.forEach((field, index) => {
      parameters += (index + 1) + '. The field should be called: \'' + field.name + '\'. It should be a ' + field.type + ' - its description is: "' + field.description + '",\n';
    });

    let fileFormat = 'The response should be formatted in ' + outputFormat + ' format.';

    return parameters + '\n' + fileFormat;
  };

  const handleDownload = async () => {
    if (!validateFields()) {
      toast.error('Please fill out all fields before downloading.');
      return;
    }

    const formData = new FormData();

    let prompt = generatePrompt();

    if (files.length > 0) {
      formData.append('file_info', JSON.stringify(files));
    }

    formData.append('output_format', outputFormat.slice(1));

    formData.append('prompt', prompt);

    try {
      const response = await axios.post('/extract/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob'
      });

      const reader = new FileReader();
      reader.onload = function(event) {
        const data = event.target.result;

        downloadCsv(data, `file${outputFormat}`, 'text/csv');
      };

      reader.readAsText(response.data);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred. Please try again.' + error,
      });
    }
  };

  return (
    <Col>
      <Card className="shadow mx-0">
        <CardBody>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <CardTitle tag="h3" className="mx-0 mb-2">Extract</CardTitle>
            <Button className="pb-4"
              style={{ background: 'none', border: 'none', padding: 0 }}
              onClick={() => onRemoveCard(id)}
            >
              <FaTimes size={20} color="black" />
            </Button>
          </div>
          <h5 className="mb-0 mt-2" onClick={() => setIsDataCollapsed(!isDataCollapsed)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
            {isDataCollapsed ? <FaCaretRight className="mr-2" /> : <FaCaretDown className="mr-2" />}
            Selected Data
          </h5>
          <Collapse isOpen={!isDataCollapsed}>
            <div className="m-0">
              <FileUploadComponent
                onFileUpload={onFileUpload}
                buttonText="+Add Data"
                buttonColor="primary"
                allowMultiple={true}
                files={files}
                onDeleteFile={onFileDelete}
                id={id}
                action="Extract"
              />
            </div>
          </Collapse>
          <hr />
          <h5 className="mb-0 mt-2" onClick={() => setIsFieldParamsCollapsed(!isFieldParamsCollapsed)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
            {isFieldParamsCollapsed ? <FaCaretRight className="mr-2" /> : <FaCaretDown className="mr-2" />}
            Field Parameters
          </h5>
          <Collapse isOpen={!isFieldParamsCollapsed}>
            <ExtractCardFieldParameters fields={fields} setFields={setFields} addField={addField} fieldErrors={fieldErrors} />
          </Collapse>
          <hr />
          <ActionOutputDownload
            isButtonDisabled={isButtonDisabled}
            handleDownload={handleDownload}
            outputFormat={outputFormat}
            setOutputFormat={setOutputFormat}
          />
        </CardBody>
      </Card>
    </Col>
  );
});

ExtractCard.displayName = 'ExtractCard';

ExtractCard.propTypes = {
  id: PropTypes.number.isRequired, // Ensure this matches the ID type you pass
  isButtonDisabled: PropTypes.bool.isRequired,
  onRemoveCard: PropTypes.func.isRequired,
  onFileUpload: PropTypes.func.isRequired,
  onFileDelete: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
};

export default ExtractCard;
