import MainLayout from 'components/MainLayout';
import { Container } from 'reactstrap';

const Subscription = () => {

  return (
    <MainLayout title="Subscription Details" header="Subscription Details">
      <Container fluid>
        <>
          <stripe-pricing-table
            pricing-table-id="prctbl_1ODVzULEGmDLb8a4wcHv5CM2"
            publishable-key="pk_test_51O5orlLEGmDLb8a4Mr1Qc73AURI1sdpllSZmjKXwFdAdsE8FVsILFNZYOaYYzKGMq90iyIEVpi1pgez5UxjYFnzC005910SdXp"
          >
          </stripe-pricing-table>
        </>
      </Container>
    </MainLayout>
  );
};

export default Subscription;
