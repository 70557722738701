import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import GoogleDriveNavigator from './GoogleDriveNavigator';
import axiosInstance from 'utils/axiosService';
import { Modal, ModalBody, ModalHeader, ListGroup, ListGroupItem, Input, Button, FormGroup, Form, Col } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import {toast} from 'react-toastify';
import 'styles/main.css';

const GoogleSheets = ({ onSave }) => {
  const [isWorksheetModalOpen, setWorksheetModalOpen] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [worksheets, setWorksheets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sheetName, setSheetName] = useState('');

  const handleFileSelect = (fileId, fileName) => {
    setSelectedFileId(fileId);
    setSelectedFileName(fileName);
    setWorksheetModalOpen(true);
  };

  const handleSheetSelect = (worksheet) => {
    setSheetName(worksheet);
    setWorksheetModalOpen(false);
    handleSave(selectedFileId, selectedFileName, worksheet);
  };
  const handleManualSheetSelect = () => {
    setWorksheetModalOpen(false);
    handleSave(selectedFileId, selectedFileName, sheetName);
    setSheetName(''); // Resetting the manually entered sheet name
  };

  const closeWorksheetModal = () => {
    setWorksheetModalOpen(false);
    setSelectedFileId(null);
  };

  const handleSave = (workbookId, workbookName, sheetName) => {
    if (onSave) {
      const integrationData = {
        integration_id: 1,
        file_name: workbookName,
        workbook_id: workbookId,
        sheet_name: sheetName,
        format: 'json',
      };
      onSave(integrationData);
    }
  };

  useEffect(() => {
    const fetchWorksheets = async () => {
      if (selectedFileId) {
        setLoading(true);
        try {
          const response = await axiosInstance.get(`/integrations/google/list-worksheets?workbook_id=${selectedFileId}`);
          setWorksheets(response.data);
        } catch (error) {
          toast.error('Error fetching worksheets:' + error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchWorksheets();
  }, [selectedFileId]);

  return (
    <div className="d-flex flex-column align-items-start">
      <GoogleDriveNavigator onFileSelect={handleFileSelect} supportedFormats={['application/vnd.google-apps.spreadsheet']} />

      <Modal isOpen={isWorksheetModalOpen} toggle={closeWorksheetModal}>
        <ModalHeader toggle={closeWorksheetModal}>Select a Worksheet</ModalHeader>
        <ModalBody>
          {loading ? (
            <div>Loading worksheets...</div>
          ) : (
            <>
              <ListGroup>
                {worksheets.map((worksheet, index) => (
                  <ListGroupItem key={index} onClick={() => handleSheetSelect(worksheet)}>
                    {worksheet}
                  </ListGroupItem>
                ))}
              </ListGroup>
              <Form className="mt-3">
                <FormGroup row>
                  <Col sm={8}>
                    <Input
                      id="manualSheetName"
                      type="text"
                      value={sheetName}
                      onChange={(e) => setSheetName(e.target.value)}
                      placeholder="Create new sheet"
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={{ size: 10, offset: 2 }}>
                    <Button
                      block
                      onClick={handleManualSheetSelect}
                      disabled={!sheetName.trim()}
                    >
                          Select Sheet
                    </Button>
                  </Col>
                </FormGroup>
              </Form>
            </>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

GoogleSheets.propTypes = {
  onSave: PropTypes.func,
};

export default GoogleSheets;
