import React, { useState, useEffect } from 'react';
import { Container, FormGroup, Label, Input, Button } from 'reactstrap';
import Select from 'react-select';
import axios from 'utils/axiosService';
import 'styles/onboarding.css';

const Onboarding = () => {
  const [dropdownData, setDropdownData] = useState({
    roles: [],
    sectors: [],
    companySizes: [],
    aiExperiences: []
  });

  const [state, setState] = useState({
    first_name: '',
    last_name: '',
    user_details: {
      company_name: '',
      sector: null,
      company_size: null,
      role: null,
      ai_experience: null,
      other_role: '',
      other_rector: ''
    },
  });

  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const response = await axios.get('account/onboarding-data/');

        // Map the data for react-select compatibility
        const sectors = response.data.sectors.map(sector => ({ label: sector.name, value: sector.id }));
        const roles = response.data.roles.map(role => ({ label: role.name, value: role.id }));
        const companySizes = response.data.company_sizes.map(size => ({ label: size.size, value: size.id }));
        const aiExperiences = response.data.ai_experiences.map(exp => ({ label: exp.level, value: exp.id }));

        setDropdownData({
          roles,
          sectors,
          companySizes,
          aiExperiences
        });
      } catch (error) {
        console.error('Error fetching dropdown data:', error);
      }
    };
    fetchDropdownData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name in state) {
      setState(prevState => ({
        ...prevState,
        [name]: value
      }));
    } else {
      setState(prevState => ({
        ...prevState,
        user_details: {
          ...prevState.user_details,
          [name]: value
        }
      }));
    }
  };

  const handleSelectChange = (name, selectedOption) => {
    const value = selectedOption ? selectedOption.value : null;
    if (name in state) {
      setState(prevState => ({
        ...prevState,
        [name]: value
      }));
    } else {
      setState(prevState => ({
        ...prevState,
        user_details: {
          ...prevState.user_details,
          [name]: value
        }
      }));
    }
  };

  const handleSubmit = () => {
    axios.patch('account/', state)
      .then(() => {
        window.location.reload();
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  };

  useEffect(() => {
    const onboardingDiv = document.querySelector('.onboardingContainer');

    if (onboardingDiv) {
      document.body.style.background = 'linear-gradient(to bottom, #01090D, #5717A7)';
    } else {
      document.body.style.background = '';
    }
  }, []);


  return (
    <div className="onboardingContainer">
      <Container className="onboardingCard">
        <h2 className="onboardingTitle">Welcome to NeuroPlug</h2>
        <FormGroup>
          <Label for="firstName">First Name <span style={{color: 'red'}}>*</span></Label>
          <Input type="text" id="firstName" name="first_name" value={state.first_name} onChange={handleInputChange} />
        </FormGroup>
        <FormGroup>
          <Label for="lastName">Last Name <span style={{color: 'red'}}>*</span></Label>
          <Input type="text" id="lastName" name="last_name" value={state.last_name} onChange={handleInputChange} />
        </FormGroup>
        <FormGroup>
          <Label for="companyName">Company Name <span style={{color: 'red'}}>*</span></Label>
          <Input type="text" id="companyName" name="company_name" value={state.user_details.company_name} onChange={handleInputChange} />
        </FormGroup>
        <FormGroup>
          <Label for="sector">Company Sector <span style={{color: 'red'}}>*</span></Label>
          <Select
            id="sector"
            options={dropdownData.sectors}
            isSearchable
            placeholder="Please Select"
            value={dropdownData.sectors.find(option => option.value === state.user_details.sector)}
            onChange={(selectedOption) => handleSelectChange('sector', selectedOption)}
          />
          {state.sector === 29 && (
            <Input
              className="mt-2"
              type="text"
              placeholder="Please specify"
              value={state.user_details.other_sector}
              onChange={(e) => setState({ ...state, user_details: { ...state.user_details, other_sector: e.target.value }})}
            />
          )}
        </FormGroup>
        <FormGroup>
          <Label for="role">Your Role <span style={{color: 'red'}}>*</span></Label>
          <Select
            id="role"
            options={dropdownData.roles}
            isSearchable
            placeholder="Please Select"
            value={dropdownData.roles.find(option => option.value === state.user_details.role)}
            onChange={(selectedOption) => handleSelectChange('role', selectedOption)}
          />
          {state.role === 17 && (
            <Input
              className="mt-2"
              type="text"
              placeholder="Please specify"
              value={state.user_details.other_role}
              onChange={(e) => setState({ ...state, user_details: { ...state.user_details, other_role: e.target.value }})}
            />
          )}
        </FormGroup>
        <FormGroup>
          <Label for="companySize">Company Size <span style={{color: 'red'}}>*</span></Label>
          <Select
            id="companySize"
            options={dropdownData.companySizes}
            placeholder="Please Select"
            value={dropdownData.companySizes.find(option => option.value === state.user_details.company_size)}
            onChange={(selectedOption) => handleSelectChange('company_size', selectedOption)}
            isSearchable={false}
          />
        </FormGroup>
        <FormGroup>
          <Label for="aiExperience">Level of Experience with AI <span style={{color: 'red'}}>*</span></Label>
          <Select
            id="aiExperience"
            options={dropdownData.aiExperiences}
            placeholder="Please Select"
            value={dropdownData.aiExperiences.find(option => option.value === state.user_details.ai_experience)}
            onChange={(selectedOption) => handleSelectChange('ai_experience', selectedOption)}
            isSearchable={false}
          />
        </FormGroup>
        <div className="submitButton">
          <Button color="success" type="submit" onClick={handleSubmit}>Confirm</Button>
        </div>
      </Container>
    </div>
  );
};

export default Onboarding;
