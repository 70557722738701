import React, { useState, useEffect, useCallback } from 'react';
import axios from 'utils/axiosService';
import MainLayout from 'components/MainLayout';
import WorkflowTable from 'components/Workflows/WorkflowTable';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';


const ListWorkflows = () => {
  const [workflows, setWorkflows] = useState([]);
  const navigate = useNavigate();

  const fetchWorkflows = useCallback(async () => {
    try {
      const response = await axios.get('workflows/');
      setWorkflows(response.data);
    } catch (error) {
      if (error.isUnauthorizedError) {
        navigate('/login');
      }
    }
  }, [navigate]);

  useEffect(() => {
    fetchWorkflows();
  }, [fetchWorkflows]);

  const createNewWorkflow = () => {
    navigate('/workflows/create');
  };

  const onDeleteSelected = () => {
    fetchWorkflows();
  };

  return (
    <MainLayout title="Workflows" header="Workflows">
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
        <Button color="primary" onClick={createNewWorkflow}>Create New Workflow</Button>
      </div>
      <WorkflowTable workflows={workflows} onDeleteSelected={onDeleteSelected} />
    </MainLayout>
  );
};

export default ListWorkflows;
