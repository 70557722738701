import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Table } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import axiosService from 'utils/axiosService';
import moment from 'moment';
import SelectAllCheckbox from 'components/Common/SelectAllCheckbox';
import TrashIcon from 'components/Common/TrashIcon';

const WorkflowTable = ({ workflows, onDeleteSelected }) => {
  const [selectedWorkflows, setSelectedWorkflows] = useState([]);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (deleteConfirmed) {
      setSelectedWorkflows([]);
      setDeleteConfirmed(false);
    }
  }, [deleteConfirmed]);

  const handleRowClick = (workflowId) => {
    navigate(`/workflows/${workflowId}`);
  };

  const handleCheckBoxClick = (e, workflowId) => {
    e.stopPropagation();
    if (selectedWorkflows.includes(workflowId)) {
      setSelectedWorkflows(selectedWorkflows.filter(id => id !== workflowId));
    } else {
      setSelectedWorkflows([...selectedWorkflows, workflowId]);
    }
  };

  const handleTrashClick = (workflowId) => {
    handleDelete(workflowId);
  };

  const handleSelectAllChange = (e) => {
    if (e.target.checked) {
      const allWorkflowIds = workflows.map(workflow => workflow.id);
      setSelectedWorkflows(allWorkflowIds);
    } else {
      setSelectedWorkflows([]);
    }

    const checkboxes = document.querySelectorAll('.checkbox-select');
    checkboxes.forEach(checkbox => {
      checkbox.checked = e.target.checked;
    });
  };

  const handleDelete = (workflowIdToDelete) => {
    let workflowsForDeletion = selectedWorkflows;
    if (workflowIdToDelete != null) {
      workflowsForDeletion = [workflowIdToDelete];
    }

    Swal.fire({
      title: 'Confirmation',
      text: 'Are you sure you want to delete the selected workflows?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Wrap deletion requests in Promise.all to ensure all requests finish before proceeding
        await Promise.all(
          workflowsForDeletion.map(workflowId => axiosService({
            method: 'DELETE',
            url: `/workflows/${workflowId}`,
          }))
        );

        // Trigger the delete confirmation
        setDeleteConfirmed(true);

        // Trigger parent callback function to refetch workflows
        onDeleteSelected();

        uncheckSelectBoxes();

        Swal.fire(
          'Deleted!',
          'The workflow(s) have been deleted.',
          'success'
        );
      }
    });
  };


  return (
    <div className="table-responsive">
      <SelectAllCheckbox
        checked={selectedWorkflows.length === workflows.length && selectedWorkflows !== 0}
        onChange={handleSelectAllChange}
      />
      <Table hover>
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Name</th>
            <th scope="col">Prompt</th>
            <th scope="col">Created At</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {workflows.map((workflow) => (
            <tr
              key={workflow.id}
              style={{ cursor: 'pointer' }}
              onClick={() => handleRowClick(workflow.id)}
            >
              <td>
                <input
                  type="checkbox"
                  className="form-check-input checkbox-select"
                  onClick={(e) => handleCheckBoxClick(e, workflow.id)}
                />
              </td>
              <td>{workflow.name}</td>
              <td>{workflow.prompt}</td>
              <td>{moment(workflow.created_on).format('HH:mm:ss DD/MM/YYYY')}</td>
              <td>
                <TrashIcon objectId={workflow.id} onTrashClick={handleTrashClick} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Button
        type="button"
        color="danger"
        onClick={() => handleDelete()}
        disabled={selectedWorkflows.length === 0}
      >
                Delete Selected
      </Button>
    </div>
  );
};

WorkflowTable.propTypes = {
  workflows: PropTypes.array.isRequired,
  onDeleteSelected: PropTypes.func.isRequired,
};

export default WorkflowTable;

function uncheckSelectBoxes() {
  const checkboxes = document.querySelectorAll('.checkbox-select');
  checkboxes.forEach(checkbox => {
    checkbox.checked = false;
  });
}
