import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { format } from 'date-fns';

const SmallUsageGraph = ({ data }) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (data) {
      const labels = data.metrics.map(item => format(new Date(item.period), 'EEE'));
      const datasetData = data.metrics.map(item => item.total_tokens);
      setChartData({
        labels: labels,
        datasets: [
          {
            label: 'Token Usage',
            data: datasetData,
            backgroundColor: 'rgb(169, 99, 255)',
            borderWidth: 0,
          },
        ],
      });
    }
  }, [data]);

  return (
    <div style={{ width: '654px', height: '178px' }}>
      {chartData ? (
        <Bar
          data={chartData}
          options={{
            maintainAspectRatio: false,
            scales: {
              x: {
                type: 'category',
                title: {
                  display: true,
                  text: 'Time',
                },
              },
              y: {
                beginAtZero: true,
                title: {
                  display: true,
                },
              },
            },
            plugins: {
              tooltip: {
                mode: 'index',
                callbacks: {
                  label: context => `Tokens: ${context.parsed.y}`,
                },
              },
              legend: {
                display: false,
              }
            },
          }}
        />
      ) : (
        <p>Loading graph...</p>
      )}
    </div>
  );
};

SmallUsageGraph.propTypes = {
  data: PropTypes.shape({
    metrics: PropTypes.arrayOf(
      PropTypes.shape({
        period: PropTypes.string,
        total_tokens: PropTypes.number
      })
    )
  })
};

export default SmallUsageGraph;
