import React, { useState, useRef, useEffect } from 'react';
import MainLayout from 'components/MainLayout';
import { FaEdit } from 'react-icons/fa';
import ExtractCard from 'components/Cards/Extract/ExtractCard';
import GenerateCard from 'components/Cards/GenerateCard';
import CustomCard from 'components/Cards/Custom/CustomCard';
import ClassifyCard from 'components/Cards/Classify/ClassifyCard';
import AddActionCard from 'components/Cards/AddActionCard';
import '../../styles/main.css';
import { Button } from 'reactstrap';

const CreateWorkflowV2 = () => {
  const [headerTitle, setHeaderTitle] = useState('New Workflow');
  const [editingTitle, setEditingTitle] = useState(false);
  const [cards, setCards] = useState([]);
  const [fileInfos, setFileInfos] = useState({});
  const cardRefs = useRef({});

  useEffect(() => {
    cardRefs.current = cards.reduce((acc, card) => {
      if (!acc[card.id]) {
        acc[card.id] = React.createRef();
      }
      return acc;
    }, {});
  }, [cards]);

  const handleCardTitleChange = (newTitle) => {
    setHeaderTitle(newTitle);
  };

  const handleIconClick = () => {
    setEditingTitle(true);
  };

  const handleTitleBlur = () => {
    setEditingTitle(false);
  };

  const getNextAvailableId = () => {
    const ids = cards.map(card => card.id);
    let newId = 0;
    while (ids.includes(newId)) {
      newId++;
    }
    return newId;
  };

  const addCard = (action) => {
    const newCard = { id: getNextAvailableId(), action, dataUploaded: false };
    setCards([...cards, newCard]);
    console.log(`Just added a ${action} card and its ID is ${newCard.id}`);
  };

  const removeCard = (id) => {
    setCards(prevCards => {
      const newCards = prevCards.filter(card => card.id !== id).map(card => {
        if (card.id > id) {
          card.id -= 1;
        }
        return card;
      });
      return newCards;
    });
    setFileInfos(prevFileInfos => {
      const newFileInfos = {};
      Object.keys(prevFileInfos).forEach(key => {
        const newKey = parseInt(key, 10);
        if (newKey > id) {
          newFileInfos[newKey - 1] = prevFileInfos[key];
        } else if (newKey < id) {
          newFileInfos[newKey] = prevFileInfos[key];
        }
      });
      return newFileInfos;
    });
    delete cardRefs.current[id];
  };

  const handleFileUpload = (id, fileInfo) => {
    console.log(`Uploading file to card ${id}:`, fileInfo);
    setFileInfos((prevFileInfos) => {
      const newFileInfos = { ...prevFileInfos };
      if (!newFileInfos[id]) {
        newFileInfos[id] = [];
      }
      newFileInfos[id].push(fileInfo);
      console.log('Updated File Infos:', newFileInfos);
      return newFileInfos;
    });
  };

  const handleFileDelete = (id, index) => {
    console.log(`Deleting file from card ${id} at index ${index}`);
    setFileInfos((prevFileInfos) => {
      const newFileInfos = { ...prevFileInfos };
      if (newFileInfos[id]) {
        newFileInfos[id].splice(index, 1);
      }
      console.log('Updated File Infos after delete:', newFileInfos);
      return newFileInfos;
    });
  };

  const hasGenerateCard = cards.some(card => card.action === 'Generate');

  const handleRun = () => {
    const data = cards.map((card) => {
      const ref = cardRefs.current[card.id];
      if (ref.current) {
        return {
          id: card.id,
          action: card.action,
          prompt: ref.current.generatePrompt(),
          fileInfo: fileInfos[card.id] || [],
        };
      }
      return null;
    }).filter(cardData => cardData !== null);

    console.log('Generated Data:', data);
  };

  return (
    <MainLayout title="Create Workflow">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          {editingTitle ? (
            <input
              type="text"
              value={headerTitle}
              onChange={(e) => handleCardTitleChange(e.target.value)}
              onBlur={handleTitleBlur}
              autoFocus
              className="form-control header-input"
            />
          ) : (
            <>
              <h1 className="header-title">{headerTitle}</h1>
              <FaEdit onClick={handleIconClick} className="edit-icon ml-2"/>
            </>
          )}
        </div>
        <Button color="primary" className="save-button" onClick={handleRun}>
          Run
        </Button>
      </div>
      <hr/>
      <div className="cards-scroll-container">
        <div className="cards-container">
          {cards.map((card) => (
            card.action === 'Extract' ? (
              <ExtractCard
                key={card.id}
                id={card.id}
                dataUploaded={card.dataUploaded}
                isButtonDisabled={!hasGenerateCard}
                onRemoveCard={() => removeCard(card.id)}
                onFileUpload={(fileInfo) => handleFileUpload(card.id, fileInfo)}
                onFileDelete={(index) => handleFileDelete(card.id, index)}
                files={fileInfos[card.id] || []}
                ref={cardRefs.current[card.id]}
              />
            ) : card.action === 'Generate' ? (
              <GenerateCard
                key={card.id}
                id={card.id}
                dataUploaded={card.dataUploaded}
                onRemoveCard={() => removeCard(card.id)}
              />
            ) : card.action === 'Custom' ? (
              <CustomCard
                key={card.id}
                id={card.id}
                dataUploaded={card.dataUploaded}
                isButtonDisabled={!hasGenerateCard}
                onRemoveCard={() => removeCard(card.id)}
                onFileUpload={(fileInfo) => handleFileUpload(card.id, fileInfo)}
                onFileDelete={(index) => handleFileDelete(card.id, index)}
                files={fileInfos[card.id] || []}
                ref={cardRefs.current[card.id]}
              />
            ) : card.action === 'Classify' ? (
              <ClassifyCard
                key={card.id}
                id={card.id}
                isButtonDisabled={!hasGenerateCard}
                onRemoveCard={() => removeCard(card.id)}
                onFileUpload={(fileInfo) => handleFileUpload(card.id, fileInfo)}
                onFileDelete={(index) => handleFileDelete(card.id, index)}
                files={fileInfos[card.id] || []}
                ref={cardRefs.current[card.id]}
              />
            ) : null
          ))}
          <AddActionCard onAddCard={addCard} />
        </div>
      </div>
    </MainLayout>
  );
};

export default CreateWorkflowV2;
