import React, { useState, useEffect } from 'react';
import axios from 'utils/axiosService';
import { Container, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { useParams } from 'react-router-dom';
import MainLayout from 'components/MainLayout';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const IntegrationDetails = () => {
  const { id } = useParams();
  const [details, setDetails] = useState({});

  const initiateOAuth = async () => {
    try {
      const token = localStorage.getItem('token');

      window.location.href = process.env.REACT_APP_DJANGO_HOST + `/accounts/google/login/?token=${JSON.parse(token)}`;

    } catch (error) {
      console.error('Error initiating OAuth2:', error);
    }
  };

  useEffect(() => {
    const fetchIntegrationDetails = async () => {
      try {
        const integrationResponse = await axios.get(`integrations/${id}/`);

        // Merge the responses into a single object to set in the state
        setDetails({
          ...integrationResponse.data,
        });
      } catch (error) {
        toast.error('An error occurred while reading integration details.');
      }
    };

    fetchIntegrationDetails();
  }, [id]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.put(`/integrations/${id}/`, {
        auth_method: details.auth_method,
        auth_token: details.auth_token
      });
      toast.success('Integration details updated successfully!');
    } catch (error) {
      toast.error('An error occurred while updating.');
    }
  };

  return (
    <MainLayout title="Integration Details" header="Integration Details">
      <Container>
        <div className="integration-header mb-5">
          <img src={process.env.REACT_APP_DJANGO_HOST + '/static/' + details?.icon} alt={details.integration?.name} width="50" height="50" />
          <h2>{details.integration?.name}</h2>
        </div>
        <Form onSubmit={handleSubmit}>
          <FormGroup className="mb-3">
            <Label for="auth_method"><strong>Auth Method:</strong></Label>
            <Input
              type="text"
              id="auth_method"
              name="auth_method"
              required
              value={details.auth_method || ''}
              onChange={(e) => setDetails({ ...details, auth_method: e.target.value })}
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <Label for="auth_token"><strong>Auth Token:</strong></Label>
            <Input
              type="text"
              id="auth_token"
              name="auth_token"
              required
              value={details.auth_token || ''}
              onChange={(e) => setDetails({ ...details, auth_token: e.target.value })}
            />
          </FormGroup>
          <Button onClick={initiateOAuth} color="primary">Authorize with Google</Button>
          <Button color="success" type="submit">Save</Button>
        </Form>
      </Container>
    </MainLayout>
  );
};

export default IntegrationDetails;
