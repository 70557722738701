export const downloadCsv = (data, fileName, mimeType) => {
  const formattedData = data
    .replace(/^"|"$/g, '')
    .replace(/\\"/g, '"')
    .replace(/\\n/g, '\n');

  const blob = new Blob([formattedData], { type: mimeType });
  const url = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();

  link.remove();
  window.URL.revokeObjectURL(url);
};