import React from 'react';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { FaTrash } from 'react-icons/fa';

const ClassifyCardFieldParameters = ({ categories, setCategories, addCategory, categoryErrors }) => {

  const handleChange = (index, event) => {
    const newCategories = categories.map((category, i) => {
      if (i === index) {
        return { ...category, [event.target.name]: event.target.value };
      }
      return category;
    });
    setCategories(newCategories);
  };

  const removeCategory = (index) => {
    const newCategories = categories.filter((_, i) => i !== index);
    setCategories(newCategories);
  };

  return (
    <>
      {categories.map((category, index) => (
        <Row key={index} className="mb-1">
          <Col>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h6 className="subtitle mt-2"><strong>Category {index + 1}</strong></h6>
              {categories.length > 1 && (
                <Button
                  style={{ background: 'none', border: 'none', padding: 0 }}
                  onClick={() => removeCategory(index)}
                >
                  <FaTrash size={14} color="red" />
                </Button>
              )}
            </div>
            <FormGroup className="d-flex align-items-center">
              <Col md={3}>
                <Label className="mr-2 mb-0" for={`name-${index}`}>Name:</Label>
              </Col>
              <Col md={9}>
                <Input className="mx-2"
                  type="text"
                  id={`name-${index}`}
                  name="name"
                  placeholder="Category Name"
                  value={category.name}
                  onChange={(e) => handleChange(index, e)}
                  style={{ borderColor: categoryErrors[index] && !category.name ? 'red' : '' }}
                />
              </Col>
            </FormGroup>
            <FormGroup className="d-flex align-items-center">
              <Col md={3}>
                <Label className="mr-2 mb-0" for={`description-${index}`}>Description:</Label>
              </Col>
              <Col md={9}>
                <Input className="mx-2"
                  type="text"
                  id={`description-${index}`}
                  name="description"
                  placeholder="Category Description"
                  value={category.description}
                  onChange={(e) => handleChange(index, e)}
                  style={{ borderColor: categoryErrors[index] && !category.description ? 'red' : '' }}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
      ))}
      <Button color="primary" onClick={addCategory}>+ Add Category</Button>
    </>
  );
};

ClassifyCardFieldParameters.propTypes = {
  categories: PropTypes.array.isRequired,
  setCategories: PropTypes.func.isRequired,
  addCategory: PropTypes.func.isRequired,
  categoryErrors: PropTypes.array.isRequired,
};

export default ClassifyCardFieldParameters;
