import React, { useState } from 'react';
import {Container, Input, Button, Row, Form, InputGroup, Col} from 'reactstrap';
import {FaSearch, FaTimes} from 'react-icons/fa';
import PropTypes from 'prop-types';

const GoogleDriveSearch = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    onSearch(null, searchTerm);
  };

  const clearSearch = () => {
    setSearchTerm('');
    onSearch(null);
  };

  return (
    <Container className="mb-4">
      <Form onSubmit={handleSearch}>
        <Row className="d-flex justify-content-center align-items-center">
          <Col lg="8">
            <InputGroup>
              <Input style={{outline: 'none', boxShadow: 'none'}} type="text" value={searchTerm} onChange={handleInputChange} placeholder="Search..." />
              {searchTerm && <Button style={{background: 'transparent', color: '#6c757d', marginLeft: '-34px', border: 'none', zIndex:'10'}} onClick={clearSearch}><FaTimes/></Button>}
              <Button type="submit"><FaSearch /></Button>
            </InputGroup>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

GoogleDriveSearch.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default GoogleDriveSearch;